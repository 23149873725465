import React from "react";
import "./Project.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaSearch,

} from "react-icons/fa";
import { Form, Input, Button, } from "antd";
import { H2, H3, H4, H5, H6, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import jull from "../../assests/image/jull.jpg";
function JhulaGhar() {
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="project-text">
              <div className="project-text1">
                <H6>JhulaGhar</H6>
              </div>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Span>JhulaGhar</Span>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className="donation-details-area">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="details-item">
              
                <img src={jull} alt=""/>
                <H2 className="">JHULA GHAR</H2>
                <div className="details-jhulaghar">
                 
                  <H5>Objectives:</H5>
                  <P>
                    Ensure a healthy and secure childhood for every child
                    through quality day care programme aimed at holistic
                    developmentof the Child. The early child care and child
                    health is crucial for the later stages of life.
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>Facilitate mainstreaming of children.</H5>
                  <P>
                    Creating enabling conditions for working women by providing
                    the necessary day care support for their children and
                    provide opportunities for skill building and empowerment of
                    women. Build awareness among the community and among various
                    stakeholders at political, social and legal levels on issues
                    affecting the lives of the children and women. Sustain the
                    developmental impact of the program.
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>Activities:</H5>
                  <P>
                    Provide integrated day care services to children of working
                    mothers at NAREGA, construction sites, slums and agriculture
                    workers. Provide supplementary nutrition and essential
                    Medicare to children, pregnant women and lactating mothers.
                    Provide stimulation and enriching environment to children
                    using play and innovative learning methods to establish a
                    strong education foundation for overall development of a
                    child
                  </P>
                  <P>
                    Organize street plays and workshops for sensitization of the
                    community on issues leading to better quality of life.
                    Establish local linkages in health to enable prompt
                    referrals of special needs children. Train community
                    workers, women in child care, facilitating them in opening
                    neighborhood crèches thus creating opportunities for
                    theirlivelihoods and better childcare practices within the
                    community.{" "}
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>Network with other NGOs and institutions.</H5>
                  <P>
                    Lobbying and advocacy with State and Central Government and
                    other decision-makers to highlight the criticality of early
                    Childcare and development programs. PMSS creates body of
                    knowledge by that influences policy towards young children
                    and their needs.
                  </P>
                  <P>
                    Working closely with the Panchayat Raj Institutions to
                    demonstrate the model of early child care and child health
                    programme so that it can be implemented by the institutional
                    of local government at large scale.
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>Infrastructure</H5>
                  <P>
                    We runs 105 crèches in tribal and rural areas of Rajgarh and
                    Guna districts in Madhya pradesh. 60 creches are supported
                    by ICCW(Indian Council for Child welfare) and 45 creches are
                    supported by CSWB(central Social Welfare Board)
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>Core thematic Area </H5>
                  <P>
                    Pragati Manav Seva Sansthan has been intensively working in
                    3 districts of Madhya Pradesh and it has been concentrating
                    on these key thematic areas of its intervention
                  </P>
                  <div className="details-share">
                    {/* <Row>
                      <Col sm={6} lg={6}>
                        <div className="left">
                          <ul>
                            <li>
                              <Span>Share:</Span>
                            </li>
                            <li>
                              <Link to="/" target="_blank">
                                <i>
                                  <TiSocialFacebook />
                                </i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/" target="_blank">
                                <i>
                                  <IoLogoTwitter />
                                </i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/" target="_blank">
                                <i>
                                  <IoLogoYoutube />
                                </i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/" target="_blank">
                                <i>
                                  <FiInstagram />
                                </i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col sm={6} lg={6}>
                        <div class="right">
                          <ul>
                            <li>
                              <Span>Tags:</Span>
                            </li>
                            <li>
                              <Link to="/">#Donation</Link>
                            </li>
                            <li>
                              <Link to="/">#Food</Link>
                            </li>
                            <li>
                              <Link to="/">#Help</Link>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row> */}
                  </div>
                </div>
              </div>
            </Col>
            {/* RRRRRRRRRR */}
                </Row>
        </Container>
      </div>
    </>
  );
}

export default JhulaGhar;
