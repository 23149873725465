import React from "react";
import "./Project.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { H2, H3, H4, H5, H6, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";


function LivelihoodProject() {
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one livehood">
        <Container>
          <div className="title-item">
            {" "}
            <div className="project-text">
              <div className="project-text1">
                <H6>Rural Livelihood Project</H6>
              </div>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Span>Rural Livelihood Project</Span>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className="donation-details-area">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="details-item">
              <H2>Rural Livelihood Education and Development</H2>
              
              <div className="details-jhulaghar">
                  <H5>Background</H5>
                  <P>
                    There has been proliferation of private and government run
                    technical institutions which specialize in vocational
                    training. However, in spite of the efforts, the number of
                    unemployed youth is constantly increasing. Many of the youth
                    trained in such vocational institutions are also unemployed
                    or end up with under employment. Hence there is an urgent
                    need for a unique and innovative market driven approach for
                    identifying and imparting training in specific areas which
                    have scope for gainful employment. Employability is assured
                    after the training as it is a demand driven training and
                    prior consent is sorted from the potential employer. Going
                    by the huge potential, the program spread should be even in
                    all regions and states of the country so as to ensure
                    replicability. The present initiative is to provide gainful
                    and sustainable employment to rural youth through intensive
                    training in specified market driven skills. The training or
                    capability building modules do not limit themselves to skill
                    training but will also address issues like personality
                    development, on the job training for hands on experience
                    etc. Each training module will be tailor made and attuned to
                    the market demand.
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>The Programme</H5>
                  <P>
                    Rural Livelihood Education And Development is a programme,
                    proposed the be designed by PMSS, It aims at providing
                    livelihood support through employability skill training to
                    dropout youths from marginalized sections of society.
                    Organization has promoted Hindu pat Institute of Skill
                    Development to work on livelihoods of resource poor rural
                    youth in its intervening areas. The programme has Innovative
                    market driven approach for training where the marked demand
                    is systematically analyzed and courses are proposed to fill
                    these demands. Emphasis of these livelihood trainings are on
                    entrepreneurship development and self-employment.
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>OBJECTIVE OF THE PROJECT</H5>
                  <ul>
                    <li>
                      To reduce the number of BPL families in target areas by
                      taping market driven employment opportunities for
                      marginalized youth with low education
                    </li>
                    <li>
                      To enhance and provide inputs for life skills and forward
                      and backward linkages for the trained youth for
                      sustainability and better quality of life.
                    </li>
                    <li>
                      To expand the project to other districts and build
                      capacities/ advocate for similar approaches in
                      employability and entrepreneurship trainings
                    </li>
                    <li>
                      To expand the project to other districts and build
                      capacities/ advocate for similar approaches in
                      employability and entrepreneurship trainings
                    </li>
                    <li>
                      To expand the project to other districts and build
                      capacities/ advocate for similar approaches in
                      employability and entrepreneurship trainings
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            {/* RRRRRRRRRR */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LivelihoodProject;
