import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import "./work.css";
import Carousel, { Modal, ModalGateway } from "react-images";
import awar1 from "../../assests/image/award/awar1.jpg";
import awar2 from "../../assests/image/award/awar2.jpg";
import awar3 from "../../assests/image/award/awar3.jpg";
import awar4 from "../../assests/image/award/awar4.jpg";
import awar13 from "../../assests/image/award/awar13.jpg";
import awar14 from "../../assests/image/award/awar14.jpg";
import awar15 from "../../assests/image/award/awar15.jpg";
import an1 from "../../assests/image/award/an1.jpg";
import an2 from "../../assests/image/award/an2.jpg";
import an3 from "../../assests/image/award/an3.jpg";
import an4 from "../../assests/image/award/an4.jpg";
import an5 from "../../assests/image/award/an5.jpg";
import an6 from "../../assests/image/award/an6.jpg";
import an7 from "../../assests/image/award/an7.jpg";
import an8 from "../../assests/image/award/an8.jpg";
import an9 from "../../assests/image/award/an9.jpg";
const photos = [
  {
    src: an1,
    width: 4,
    height: 3,
  },
  {
    src: an2,
    width: 4,
    height: 3,
  },
  {
    src: an3,
    width: 4,
    height: 3,
  },
  {
    src: an4,
    width: 4,
    height: 3,
  },
  {
    src: an5,
    width: 4,
    height: 3,
  },
  {
    src: an6,
    width: 4,
    height: 3,
  },
  {
    src: an7,
    width: 4,
    height: 3,
  },
  {
    src: an8,
    width: 4,
    height: 3,
  },
  {
    src: an9,
    width: 4,
    height: 3,
  },

  {
    src: awar1,
    width: 4,
    height: 3,
  },
  {
    src: awar2,
    width: 4,
    height: 3,
  },
  {
    src: awar3,
    width: 4,
    height: 3,
  },
  {
    src: awar4,
    width: 4,
    height: 3,
  },
  {
    src: awar13,
    width: 4,
    height: 3,
  },
  {
    src: awar14,
    width: 4,
    height: 3,
  },
  {
    src: awar15,
    width: 4,
    height: 3,
  },
];

const Lightboxcode1 = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <div className="light1box mb-3">
        <Gallery photos={photos} onClick={openLightbox} />
      </div>

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};
export default Lightboxcode1;
