import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import "./work.css";
import Carousel, { Modal, ModalGateway } from "react-images";
import hj1 from "../../assests/image/hj1.jpg";
import hj2 from "../../assests/image/hj2.jpg";
import n2 from "../../assests/image/n2.png";
import n3 from "../../assests/image/n3.png";
import n5 from "../../assests/image/n5.png";
import n6 from "../../assests/image/n6.png";
import n7 from "../../assests/image/n7.png";
import eye from "../../assests/image/eye.jpg";
import heathcare from "../../assests/image/heathcare.jpg";
const photos = [
  {
    src: hj1,
    width: 4,
    height: 3,
  },
  {
    src: heathcare,
    width: 4,
    height: 3,
  },
  {
    src: hj2,
    width: 4,
    height: 3,
  },
  {
    src: n2,
    width: 4,
    height: 3,
  },
  {
    src: n3,
    width: 4,
    height: 3,
  },
  {
    src: n5,
    width: 4,
    height: 3,
  },
  {
    src: n6,
    width: 4,
    height: 3,
  },
  {
    src: n7,
    width: 4,
    height: 3,
  },
  {
    src: eye,
    width: 4,
    height: 3,
  },
];

const Lightboxcode2 = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <div className="light1box mb-3">
        <Gallery photos={photos} onClick={openLightbox} />
      </div>

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};
export default Lightboxcode2;
