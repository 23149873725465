export const photos = [
  {
    src: "../static/media/gallery1.9f94c382.jpg",
    width: 4,
    height: 3
   
  },
  {
    src: "../static/media/gallery2.228c109d.jpg",
    width: 5,
    height: 4
  },
  {
     src: "../static/media/gallery3.d1b5891a.jpg",
    width: 4,
    height: 4
  },
  {
    src: "../static/media/dd2.6180c972.png",
   width: 4,
   height: 3
 },
  {
    src: "../static/media/gallery4.d321dffe.jpeg",
    width: 5,
    height: 4
  },
  
  {
     src: "../static/media/gallery5.c106ea81.jpg",
    width: 5,
    height: 4
  },
  {
     src: "../static/media/health2.4f1d735f.jpg",
    width: 4,
    height: 3
  },
 
   
];
