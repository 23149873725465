import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { H2, H3, H4, H5, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import l1 from "../../assests/image/logo/l1.jpg";
import l2 from "../../assests/image/logo/l2.jpg";
import l3 from "../../assests/image/logo/l3.jpg";
import l4 from "../../assests/image/logo/l4.jpg";
import l5 from "../../assests/image/logo/l5.jpg";
import l6 from "../../assests/image/logo/l6.jpg";
import l7 from "../../assests/image/logo/l7.jpg";
import l8 from "../../assests/image/logo/l8.jpg";
import l9 from "../../assests/image/logo/l9.jpg";
import l10 from "../../assests/image/logo/l10.jpg";
import l11 from "../../assests/image/logo/l11.jpg";
import l12 from "../../assests/image/logo/l12.jpg";
import l13 from "../../assests/image/logo/l13.jpg";
import l14 from "../../assests/image/logo/l14.jpg";
import l15 from "../../assests/image/logo/l15.jpg";
import l16 from "../../assests/image/logo/l16.jpg";
// import team1 from "../../assests/image/team1.jpg";
import education2 from "../../assests/image/education2.jpg";
import donation4 from "../../assests/image/donate/donation4.jpg";
import media2 from "../../assests/image/media2.jpg";
import event4 from "../../assests/image/event4.jpg";
import gas from "../../assests/image/biogas/gas.jpg";
import shg from "../../assests/image/shg.jpg";
import bank1 from "../../assests/image/bank1.jpg";
import d66 from "../../assests/image/biogas/d66.jpg";
import eye from "../../assests/image/eye.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
function Pastproject() {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3700,
    autoplaySpeed: 3700,
    cssEase: "linear",
  };
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Past project</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Past project</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <section className=" prt-text">
        <Container>
          
          <div className="details-item">
            <H2>Our Projects</H2>
         
          <Row>
            <Col md={4}>
              <div className="proto-txet">
                <img src={eye} alt=""/> 
                <div className="ourproject-text">
                  <H4>Healthcare</H4>
                  <P>
                  We are working with optimised health care start up founded by Dr. Ranu Dillan, Prof. 
                  form Harward Medical School, in providing quality primary health care in rural areas
                  </P>
                  <Link to="/Past_project_Details/1"  className="press-view">View More</Link>
                </div>
           
              </div>
            </Col>
            <Col md={4}>
              <div className="proto-txet">
                <img src={education2} alt=""/>
                <div className="ourproject-text">
                  <H4>The Educational Notes in Mughal Canal, Karnal</H4>
                  <P>
                  The Educational Notes in Karnal is one of the leading businesses in the Online MBA Project Reports. 
                  </P>
                  <Link to="/Past_project_Details/2"  className="press-view">View More</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="proto-txet">
                <img src={donation4} alt=""/>
                <div className="ourproject-text">
                  <H4>Sewing center</H4>
                  <P>
                    We are working with optimised health care start up founded
                    by Dr. Ranu Dillan, Prof. form Harward Medical School
                  </P>
                  <Link to="/Past_project_Details/3"  className="press-view">View More</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="proto-txet">
                <img src={media2} alt=""/>
                <div className="ourproject-text">
                  <H4>Skd Consultants Pvt Ltd</H4>
                  <P>
                  Skd Consultants Pvt Ltd in College Road has a wide range of products and services to cater to the varied
                   requirements of their customers.
                  </P>
                  <Link to="/Past_project_Details/4"  className="press-view">View More</Link>
                </div>
              </div>
            </Col>
          
            <Col md={4}>
              <div className="proto-txet">
                <img src={event4} alt=""/>
                <div className="ourproject-text">
                  <H4>F. L. Electronics Pvt. Ltd.</H4>
                  <P>
                  Manufacturer of investors, automatic emergency lights and remote operated home application.
                  
                  </P>
                  <Link to="/Past_project_Details/5"  className="press-view">View More</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="proto-txet">
                <img src={gas} alt=""/>
                <div className="ourproject-text">
                  <H4>Gas Authority Of India Limited</H4>
                  <P>
                  Gas Authority Of India Limited in Guna is one of the leading businesses in the LPG Filling Stations.
                  </P>
                  <Link to="/Past_project_Details/6" className="press-view">View More</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="proto-txet">
                <img src={shg} alt=""/>
                <div className="ourproject-text">
                  <H4>SHG GROUP</H4>
                  <P>
                  Women from various Self Help Group (SHG) trained by us showcased various products like pickles, papad,
                   home decor and garments.
                  </P>
                  <Link to="/Past_project_Details/7" className="press-view">View More</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="proto-txet">
                <img src={bank1} alt=""/>
                <div className="ourproject-text">
                  <H4>Pro Projects Pvt Ltd</H4>
                  <P>
                  Pro Projects Pvt Ltd in Dehradun is one of the leading businesses in the Project Consultants.
                   
                  </P>
                  <Link to="/Past_project_Details/8"  className="press-view">View More</Link>
                </div>
              </div>
            </Col>
          </Row>
          </div>
        </Container>
      </section>
      <section className="ptr1-text">
        <Container>
          <Row className="mt-5 mb-4">
            <Col md={6}>
              <div className="projectplan-text">
                <H2>Future Plan</H2>
                <H5>Bio Gas Plant</H5>
                <P>
                  As a part of our Prime Ministers vision to increase farmers'
                  income to double by the year 2022, Banas Dairy have taken many
                  initiatives as described above including multi beneficial
                  Biogas project.
                </P>
                <H5>Project Objective</H5>
                <P>Additional income to Farmers</P>
                <P>Waste to wealth</P>
                <P>Hygiene & Environment</P>
                <P>Nutrient rich fertilizer</P>
                <P>Clean Energy</P>
                <P>Employment</P>
              </div>
            </Col>
            <Col md={6}>
              <div className="gas-text">
                <img src={d66} alt=""/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="App">
        <Container fluid>
        <H3>Companies/Institutions we are working with</H3>
          <Slider {...settings}>
            <div className="slit">
              <img src={l1} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l2} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l3} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l4} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l5} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l6} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l15} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l16} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              {" "}
              <img src={l7} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l8} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              {" "}
              <img src={l9} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l10} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l11} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l12} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l13} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l14} alt="lldd" className="slit-img" />
            </div>
          </Slider>
        </Container>
      </section>
    </>
  );
}

export default Pastproject;
