import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaSearch,
} from "react-icons/fa";
import { Form, Input, Button,} from "antd";
import { H2, H3, H4, H5, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
// import teamp2 from "../../assests/image/team/trainprincipa.0fc099b8.jpg";

function BoardsMember() {
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>BoardsMember</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>BoardsMember</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <div className="donation-details-area">
        <Container>
         
              <div className="details-item">
              <H2>Governing Board Member</H2>
                <Row>
                  <Col lg={12}>
                    <div className="details-img left-p">
                    
                       {/* <img className="boardmembers" src={teamp2} alt="Team" /> */}
                       <div className="member-details">
                      <H5>Smt Sreejamya Singh (Chairperson):</H5>
                      <P>
                        Smt Sreejamya Singh has been actively participating in the
                        social development. She has special interest of preserving
                        and promoting art and culture. She is also working for
                        Promotion for Woman Empowerment & Health and Education. She
                        minutely observe all our Educational institution of the
                        society to provide better aspects to the rural area of GUNA
                        District. She plays an important role in shaping the
                        programmers in Pragati to achieve the goal of inclusive
                        development and social justice.
                      </P>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="details-img right-p">
                    {/* <img className="boardmembers" src={teamp2} alt="Team" /> */}
                    <div className="member-details">
                    <H5>Shri Rakesh Mittal (Vice Chairperson):</H5>
                    <P>
                      Shri Rakesh Mittal is a Charted accountant by profession. He
                      has been a successful practicing charted accountant who is
                      also associated with many charitable and social causes in
                      the region
                    </P>
                    </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="details-img left-p">
                    {/* <img className="boardmembers" src={teamp2} alt="Team" /> */}
                    <div className="member-details">
                    <H5>Shri Niklank Jain (Treasurer):</H5>
                    <P>
                      Shri Niklank Jain has been associated with Pragati Manav
                      Seva Sansthan since 2003 had serving as treasurer of the
                      organization. Niklank Jain holds Law Degree from Jivaji
                      University Gwalior and has a successful carrier in
                      practicing income tax law. Mr. Jain has also associated with
                      numerous social organizations. He was the chairperson of
                      Loins club and member of Jain social group in Guna.
                    </P>
                    </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="details-img right-p">
                    {/* <img className="boardmembers" src={teamp2} alt="Team" /> */}
                    <div className="member-details">
                    <H5>Shri Sumer Singh Garha (Joint Secretary):</H5>
                    <P>
                      Shri Sumer Singh worked in Indian Army from 1978 to 1983. He
                      was a member in MP Public Service Commission from 2000 to
                      2006. He has been Chairman in Zila Panchayat, GUNA from 2010
                      to 2015.
                    </P>
                    </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="details-img left-p">
                    {/* <img className="boardmembers" src={teamp2} alt="Team" /> */}
                    <div className="member-details">
                    <H5>Shri .Vijay Kumar Jain (Board Member):</H5>
                    <P>
                      Shri Vijay Kumar Jain is a journalist by profession who has
                      fine understanding of the social and developmental issues of
                      the region. He has been a social worker for more than two
                      decades. Vijay Kumar Jain holds a bachelor's degree.
                    </P>
                    </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="details-img right-p">
                    {/* <img className="boardmembers" src={teamp2} alt="Team" /> */}
                    <div className="member-details">
                    <H5>Smt. Padma Lahoti (Board Member):</H5>
                  <P>
                    Smt. Padma Lahoti comes from a well-respected business
                    family of the region. She takes lot of interest in Social
                    work. She acquired bachelor's degree.
                  </P>
                  </div>
                    </div>
                  </Col>
                    
            
                  </Row>
                
                  <div className="details-share"></div>
               </div>
               </Container>
               </div>
               
   
    </>
  );
}

export default BoardsMember;
