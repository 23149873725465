import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import "./work.css";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Container, NavLink, Row, Col, Button } from "react-bootstrap";
import {
  H2,
  H1,
  H3,
  H4,
  H5,
  H6,
  P,
  Span,
} from "../../components/Typography/index";
import portfolio1 from "../../assests/image/portfolio/portfolio1.jpg";
import portfolio2 from "../../assests/image/portfolio/portfolio2.jpg";
import portfolio3 from "../../assests/image/portfolio/portfolio3.jpg";
import portfolio4 from "../../assests/image/portfolio/portfolio4.jpg";
import portfolio5 from "../../assests/image/portfolio/portfolio5.jpg";
import portfolio6 from "../../assests/image/portfolio/portfolio6.jpg";
const photos = [
  {
    src: portfolio1,
    width: 4,
    height: 3,
  },
  {
    src: portfolio2,
    width: 4,
    height: 3,
  },
  {
    src: portfolio3,
    width: 4,
    height: 3,
  },
  {
    src: portfolio4,
    width: 4,
    height: 3,
  },
  {
    src: portfolio5,
    width: 4,
    height: 3,
  },
  {
    src: portfolio6,
    width: 4,
    height: 3,
  },
];

const Lightboxcode = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <section className="compte-text">
        <Container>
          <Row>
            <Col md={12}>
              <div className="d-health mt-4">
                <H6>Our Portfolio </H6>
                <div className="line"></div>
              </div>

              <div className="new-item">
                <Gallery photos={photos} onClick={openLightbox} />
              </div>

              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={photos.map((x) => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default Lightboxcode;
