import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import { H2,H6, Span } from "../../components/Typography/index";
import Pagination from "react-responsive-pagination";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import { Link } from "react-router-dom";
import { Container, Row} from "react-bootstrap";
import "./Gallary.css";

function EventsGallery() {
  const [Active, setActive] = useState(4);
  const totalPages = 3;
  const [visible, setVisible] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-ty">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Photo Gallery</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Photo Gallery</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <section class="gallery-area pt-80">
        <div className="gallary-tee">
          <H2>Photo Gallery</H2>
          <H6>
            We are currently working in state of Madhya Pradesh (MP) whihc is
            considered the heart of the India and is well known for its
            diversity and Culture. Our head office is located in the town of
            Raghogarh which has a population of over 65000 People. Raghogarh is
            also well known for having large public sector enterprises like GAS
            Authority of India Limited (GAIL), National Fertiliser Limited
            (NFL), Indian Oil Company Limited. We also have quality education
            institutes like Jaypee University engineering & technology and Satya
            Sai Vidhya vihar and Kendriya Vidhyalaya.
          </H6>
        </div>
        <Container fluid>
          <Row>
            <Gallery
              photos={photos}
              onClick={openLightbox}
              className="phtoto"
            />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel views={photos} />
                </Modal>
              ) : null}
            </ModalGateway>
          </Row>
          <div className="galley-page">
            <Pagination
              current={Active}
              total={totalPages}
              onPageChange={setActive}
            />
          </div>
        </Container>
      </section>
    </>
  );
}

export default EventsGallery;
