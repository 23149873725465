import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col} from "react-bootstrap";
import { H2, H4,H1, H6, P,} from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import education2 from "../../assests/image/education2.jpg";


function Proposed_Projects_Detail() {
  return (
    <>
      <Topheader />
      <Navbar />
     <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Details</H2>
            <ul>
              <li>
                <Link to="/Proposedprojects">Proposedprojects</Link>
              </li>
            </ul>
          </div>
        </Container>
      </div> 
      <section className="ress-descript">
        <Container>
          <Row>
            <Col md={10} className="offset-md-1">
              <div className="press-img">
                <img src={education2} alt=""/>
              </div>
              <div className="press-1">
              <H1 className="mt-3">Proposed Projects Details</H1>
                <H4>Educational Project Proposal</H4>
                <P>
                Hindupat Institute of Teacher Training is a brainchild of a very enthusiastic, dedicated and foresighted person of 
                eminence Hon. Digvijay Singhji, Ex. CM of Madhya Pradesh. The governing board includes eminent educationalists, 
                technocrats and professionals. HITT presents an unparalleled, qualitative, exhaustive, 
                up-to-date, easy-paced training to all candidates who aspire to take up teaching as their profession
                </P>
              </div>
            </Col>
           
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Proposed_Projects_Detail;
