import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Container, Row, Col } from "react-bootstrap";
import { IoLogoTwitter, IoLogoYoutube } from "react-icons/io";
import { FiInstagram, FiPhone, FiMapPin, FiChevronRight } from "react-icons/fi";
import { TiSocialFacebook } from "react-icons/ti";
import logo1 from "../../assests/image/logo/logo1.png";
import { BsBriefcaseFill, BsGlobe } from "react-icons/bs";
import { H3, P } from "../../components/Typography/index";
import "./Footer.css";
function Footer() {
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <>
      <footer className="footer-area">
        <Container fluid>
          <Row>
            <Col lg={5}>
              <div className="footer-item">
                <div className="footer-logo">
                  <Link to="/" className="logo">
                    <img src={logo1} alt="Logo" />
                  </Link>
                  <P>
                    Pragati Manav Seva Sansthan (PMSS) is a registered
                    non-profit in India (12A/80G tax exemption and FCRA
                    certification) that was set up in Our head office is in
                    Raghogarh in Guna District of Madhya Pradesh in 1999.
                  </P>
                  <ul>
                    <li>
                      <Link to="/" target="_blank">
                        <i>
                          <TiSocialFacebook />
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" target="_blank">
                        <i>
                          <IoLogoTwitter />
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" target="_blank">
                        <i>
                          <IoLogoYoutube />
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" target="_blank">
                        <i>
                          <FiInstagram />
                        </i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <div className="footer-item">
                <div className="footer-links">
                  <H3>Quick links</H3>
                  <ul>
                    <li>
                      <Link to="/EventsGallery" onClick={topFunction}>
                        <i>
                          <FiChevronRight />
                        </i>
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to="/Team" onClick={topFunction}>
                        <i>
                          <FiChevronRight />
                        </i>
                        Team
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/Faq" onClick={topFunction}>
                        <i>
                          <FiChevronRight />
                        </i>
                        Faq
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <div className="footer-item">
                <div className="footer-contact">
                  <H3>Contact info</H3>
                  <div className="contact-inner">
                    <ul>
                      <li className="mb-3">
                        <i>
                          <FiMapPin />
                        </i>
                        <Link to="/">
                          Pragati Manav Seva Sansthan Kothi Bagh Raghogarh,
                          Dist. Guna Madhya Pradesh, India Pin Code – 473226{" "}
                        </Link>
                      </li>
                      <li>
                        <i>
                          <FiPhone />
                        </i>
                        <Link
                          onClick={() => window.location = 'tel:+917544-262400'}>
                          +91 7544-262400
                        </Link>
                        {/* <Link to="/"></Link> */}
                      </li>
                    </ul>
                  </div>
                  <div className="contact-inner">
                    <ul>
                      <li>
                        <i>
                          <BsBriefcaseFill />
                        </i>
                        <Link
                          onClick={() => window.location = 'mailto:pragatiraghogarh@gmail.com'}>
                          pragatiraghogarh@gmail.com
                        </Link>
                      </li>
                    </ul>

                    <div className="contact-inner">
                      <ul>
                        <li>
                          <i>
                            <BsGlobe />
                          </i>
                          <Link onClick={() => window.location =
                            'http://www.pragatiraghogarh.com'}>www.pragatiraghogarh.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="copyright-area">
            <P>
              Copyright ©2022 Pragati manav seva sansthan. Designed By{" "}
              <Link onClick={() => window.open(
                'https://impetrosys.com/', "_blank", 'Impetrosys')
              }>Impetrosys
              </Link>
            </P>
          </div>
        </Container>
      </footer>
      <ScrollToTop smooth color="#ff6015" />
    </>
  );
}

export default Footer;
