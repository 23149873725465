import React, { useState } from "react";
import "./work.css";
import Lightboxcode3 from "../Work/Lightboxcode3";
import Lightboxcode4 from "../Work/Lightboxcode4";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { H2,H6, P, Span, H5 } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import d66 from "../../assests/image/biogas/d66.jpg";
import d51 from "../../assests/image/biogas/d51.jpg";
import { Image } from "antd";

function Livelihoods() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one livehood">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Livelihoods</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Livelihoods</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <div className="donation-details-area ">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="details-item">
                <div className="details-jhulaghar">
                  <H5>Livelihoods</H5>

                  <P>
                    Our mission is to work with low-income families in urban and
                    rural areas and create an environment where the locals take
                    the initiative in promoting development. We aim to improve
                    the quality of life through a cross-sectorial approach
                    focused on primary healthcare (for women, children and
                    senior citizens), education, livelihood opportunities,
                    sanitation and hygiene.
                  </P>
                  <P>
                    We have tied up with sewing machine manufacturers, Usha and
                    Welspun to start a sewing center in the towns of Raghogarh
                    and Aron. A single batch consists of 50 students who receive
                    training over a 6-month period. A number of trainees have
                    started their own stitching centers after completing the
                    course. We have now acquired professional machines and are
                    giving the best performing students professional training.
                    Our vision is to now go up the value chain and create
                    self-help groups that will directly manufacture and sell
                    clothes.{" "}
                  </P>
                  <P>
                    The Centers are funded by ICCW (Indian Council of Child
                    Welfare) and CSWB (Central Social Welfare Board) Started in
                    1998 with 15 centers Each day care center serves 30
                    childrenll the age of 5 Every crèche is run by 2 women from
                    the village itself so that the society is involved in the
                    project An observer and doctor is employed for every 10
                    centers and give a weekly report
                  </P>
                </div>
              </div>
            </Col>
            {/* RRRRRRRRRR */}
          </Row>
        </Container>
      </div>
      <section>
        <Container>
          <div className="live5-text">
            <H6>Sewing center for women</H6>
          </div>
          <Lightboxcode4/>
        </Container>
      </section>
      <section>
        <Container>
          <div className="live5-text">
            <H6>SHG Groups</H6>
          </div>
          <Lightboxcode3 />
        </Container>
      </section>
      <section className="lihood">
        <Container>
          <div className="live5-text">
            <H6>
              Funding has been given by TCS to construct over 200 toilets linked
              to biogas plant
            </H6>
          </div>
          <Row>
            <Col md={6}>
              <div className="live1-text">
                <Image src={d66} preview={{ src: d66 }} />
              </div>
            </Col>
            <Col md={6}>
              <div className="live1-text">
                <Image src={d51} preview={{ src: d51 }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Livelihoods;
