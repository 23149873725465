import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Inner_Pages/Home/Home";
import BoardsMember from "./Inner_Pages/About/BoardsMember";
import DonationDetails from "./Inner_Pages/Donations/DonationDetails";
import Contact from "./Inner_Pages/Contact/Contact";
import Team from "./Inner_Pages/Pages/Team";
import VisionMission from "./Inner_Pages/About/VisionMission";
import Education from "./Inner_Pages/Work/Education";
import Skill_Development from "./Inner_Pages/Work/Skill_Development";
import Health from "./Inner_Pages/Work/Health";
import Livelihoods from "./Inner_Pages/Work/Livelihoods";
import JhulaGhar from "./Inner_Pages/Projects/JhulaGhar";
import LivelihoodProject from "./Inner_Pages/Projects/LivelihoodProject";
import HealthNutrition from "./Inner_Pages/Projects/HealthNutrition";
import Pastproject from "./Inner_Pages/Projects/Pastproject";
import ProposedProjects from "./Inner_Pages/Projects/ProposedProjects";
import Past_project_Details from "./Inner_Pages/Projects/Past_project_Details";
import Proposed_Projects_Detail from "./Inner_Pages/Projects/Proposed_Projects_Detail";
import EventsGallery from "./Inner_Pages/Events_Gallery/EventsGallery";
import PressReleases from "./Inner_Pages/Press_Releases/PressReleases";
import Report from "./Inner_Pages/Report/Report";
import Press_Releases_Descript from "./Inner_Pages/Press_Releases/Press_Releases_Descript";
import Faq from "./Inner_Pages/Pages/Faq";
import Footer from "./Comman_Componets/Footer/Footer";

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/BoardsMember" component={BoardsMember}></Route>
        <Route path="/Team" component={Team}></Route>
        <Route path="/VisionMission" component={VisionMission}></Route>
        <Route path="/Education" component={Education}></Route>
        <Route path="/Health" component={Health}></Route>
        <Route path="/Livelihoods" component={Livelihoods}></Route>
        <Route path="/Skill_Development" component={Skill_Development}></Route>
        <Route path="/JhulaGhar" component={JhulaGhar}></Route>
        <Route path="/LivelihoodProject" component={LivelihoodProject}></Route>
        <Route path="/HealthNutrition" component={HealthNutrition}></Route>
        <Route path="/Pastproject" component={Pastproject}></Route>
        <Route path="/EventsGallery" component={EventsGallery}></Route>
        <Route path="/Contact" component={Contact}></Route>
        <Route path="/PressReleases" component={PressReleases}></Route>
        <Route path="/DonationDetails" component={DonationDetails}></Route>
        <Route path="/Faq" component={Faq}></Route>
        <Route path="/Team" component={Team}></Route>
        <Route path="/Report" component={Report}></Route>
        <Route path="/Press_Releases_Descript/:Press_Releases_DescriptId" component={Press_Releases_Descript}></Route>
        <Route path="/ProposedProjects" component={ProposedProjects}></Route>
        <Route path="/Proposed_Projects_Detail/:Proposed_Projects_DetailId" component={Proposed_Projects_Detail}></Route>
        <Route path="/Past_project_Details/:Past_project_DetailsId" component={Past_project_Details}></Route>
      </Switch>
      <Footer />
    </>
  );
};

export default App;
