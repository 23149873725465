import React from "react";
import "./Topheader.css";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col,} from "react-bootstrap";
import {
  BsFillTelephoneFill,
  BsBriefcaseFill,
} from "react-icons/bs";
import {
  BsTwitter,
  BsPlayBtnFill,
} from "react-icons/bs";
import { FaFacebookF, FaInstagramSquare } from "react-icons/fa";

const Topheader = () => {
  return (
    <>
      <div className="header-area">
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className="left">
                <div className="tik">
                  <ul>
                    <li>
                      <i>
                        <BsBriefcaseFill />
                      </i>
                      <Link 
                        onClick={() => window.location = 'mailto:pragatiraghogarh@gmail.com'}>
                        pragatiraghogarh@gmail.com
                       </Link>
                    </li>
                    <li>
                      <i>
                        <BsFillTelephoneFill />
                      </i>
                      <Link
                      onClick={() => window.location = 'tel:+917544-262400'}>
                      +91 7544-262400
                       </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="right">
                <div className="tik1">
                <ul>
                  <li>  <span className="header-follow">Follow Us:</span></li>
                  <li>
                    <Link to="/" target="_blank">
                      <i className="icofont-facebook">
                        <FaFacebookF />
                      </i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      <i className="icofont-twitter">
                        <BsTwitter />
                      </i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      <i className="icofont-youtube-play">
                        <BsPlayBtnFill />
                      </i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      <i className="icofont-instagram">
                        <FaInstagramSquare />
                      </i>
                    </Link>
                  </li>
                </ul>
              </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
};

export default Topheader;
