import React from "react";
import { Link } from "react-router-dom";
import {
  FaSearch,
} from "react-icons/fa";
import { Form, Input, Button} from "antd";
import { Container, Row, Col} from "react-bootstrap";
import { H2, H3, H4, H6,  P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import mr14 from "../../assests/image/mr14.jpg";
import blogdetails1 from "../../assests/image/donate/blogdetails1.jpg";
import blogdetails2 from "../../assests/image/donate/blogdetails2.jpg";
import blogdetails3 from "../../assests/image/donate/blogdetails3.jpg";
import blogdetails4 from "../../assests/image/donate/blogdetails4.jpg";
import "./Press.css";
function Press_Releases_Descript() {
  return (
    <>
      <Topheader />
      <Navbar />
     <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Description</H2>
            <ul>
              <li>
                <Link to="/PressReleases">Press</Link>
              </li>
              <li>
                <Span>Description</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div> 
      <section className="ress-descript">
        <Container>
          <Row>
            <Col md={8}>
             
              <div className="press-img">
                <img src={mr14} alt=""/>
              </div>
              <div className="press-1">
                <H4>Publishing Press Releases</H4>
                <P>
                  The release should be written on the company letterhead, with
                  the words “Press Release” or “News Release” at the top left
                  corner of the page. Below this, indicate when the information
                  is available for publication. The term “immediate release”
                  means the information is ready to publish and can be used by
                  journalists as soon as they receive it. Occasionally, you
                  might want more time to gather other information, or would
                  prefer that the journalist publish the announcement at a later
                  Writing a press release is really only half the battle. Once
                  you're finished with production, it'll be time to focus on
                  distribution. Of course, we're all familiar with the
                  traditional distribution levers we can pull, which include
                  publishing the press release on our website/blog, as well as
                  sharing the press release with our followers/subscribers via
                  social media and email. But for ensuring a press release gets
                  the maximum amount of distribution possible, here are some
                  tips you can follow.
                </P>
                <H4>Tips for Publishing Press Releases</H4>
                <P>
                  Of course, we're all familiar with the traditional
                  distribution levers we can pull, which include publishing the
                  press release on our website/blog, as well as sharing the
                  press release with our followers/subscribers via social media
                  and email. But for ensuring a press release gets the maximum
                  amount of distribution possible, here are some tips you can
                  follow.
                </P>
                <H6>1. Reach out to specific journalists.</H6>
                <P>
                  Instead of blasting a press release out to every journalist
                  you can find an email address for, focus on a few journalists
                  who have experience covering your industry (and company,
                  hopefully) and send them personalized messages. Connect the
                  dots. Show why what you wrote connects to what they write.
                </P>
                <H6>2. Don't be afraid to go offline.</H6>
                <P>
                  Most journalists have mountains of emails (and press releases)
                  to sort through. Try sending your release through snail mail
                  or another offline channel to differentiate yourself.
                </P>
                <H6>
                  3. To avoid competition, don't publish your release on the hour
                </H6>
                <P>
                  If you're publishing your press release on a distribution
                  service like PR Newswire or Business Wire, avoid publishing it
                  on the hour (e.g., 1 p.m., or 3 p.m., or 5 p.m.). The reason?
                  Most companies schedule their releases to go out on the hour,
                  which means if your release goes out on the hour too, it's
                  more likely to get lost in the shuffle. Instead, try going
                  with a more distinct time (e.g., 1:12 p.m., or 3:18 p.m., or
                  5:22 p.m.).
                </P>
              </div>
            </Col>
            <Col lg={4}>
              <div className="widget-area">
                <div className="search widget-item">
                  <Form>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                    <Button type="submit" className="btn">
                      <i>
                        <FaSearch />
                      </i>
                    </Button>
                  </Form>
                </div>
              </div>
              <div className="post widget-item">
                <H3>Lates Events</H3>
                <div className="post-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src={blogdetails1} alt="Details" />
                    </li>
                    <li>
                      <H4>
                        <Link to="/">Swasthya Shiver Sept 2012</Link>
                      </H4>
                    </li>
                  </ul>
                </div>
                <div className="post-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src={blogdetails2} alt="Details" />
                    </li>
                    <li>
                      <H4>
                        <Link to="/">Employment fair Oct 2012</Link>
                      </H4>
                    </li>
                  </ul>
                </div>
                <div className="post-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src={blogdetails3} alt="Details" />
                    </li>
                    <li>
                      <H4>
                        <Link to="/">Employment fair July 2012</Link>
                      </H4>
                    </li>
                  </ul>
                </div>
                <div className="post-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src={blogdetails4} alt="Details" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="common-right-content widget-item">
                <H3 className="t-dt">Archives</H3>
                <ul>
                  <li>
                    <Link to="/">January 2020</Link>
                  </li>
                  <li>
                    <Link to="/">May 2020</Link>
                  </li>
                  <li>
                    <Link to="/">April 2020</Link>
                  </li>
                  <li>
                    <Link to="/">June 2020</Link>
                  </li>
                </ul>
              </div>
              <div className="common-right-content widget-item">
                <H3 className="t-dt">Categories</H3>
                <ul>
                  <li>
                    <Link to="/">Education (10)</Link>
                  </li>
                  <li>
                    <Link to="/">Medical (25)</Link>
                  </li>
                  <li>
                    <Link to="/">Food & Water (14)</Link>
                  </li>
                  <li>
                    <Link to="/">National Charity (2)</Link>
                  </li>
                  <li>
                    <Link to="/">Cloth (4)</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Press_Releases_Descript;
