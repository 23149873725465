import React, { useState } from "react";
import "./work.css";
import Lightboxcode2 from "../Work/Lightboxcode2";
import { Link } from "react-router-dom";
import { Container, NavLink, Row, Col, Modal, Button } from "react-bootstrap";
import { BsChatLeftText } from "react-icons/bs";
import Adrish from "../../assests/image/team/Adrish.png";
import Dhillon from "../../assests/image/team/Dhillon.jpg";
import Sanket from "../../assests/image/team/Sanket.jpg";
import { H2, H6, H5, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import { Image } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
function Health() {
  const [visible, setVisible] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [lg1Show, setLg1Show] = useState(false);
  const [lg2Show, setLg2Show] = useState(false);
  const [show, toggleShow] = useState(false);
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one healthcare">
        <Container>
          <div className="title-item">
            <div className="tt"></div>
            <H2>Health Care</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Health Care</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <div className="details-item mt-4">
        <Container>
          <Row>
            <Col md={12}>
              <div className="details-jhulaghar">
                <H5>Health Care</H5>
                <P>
                  We are working with optimised health care start up founded by
                  Dr. Ranu Dillan, Prof. form Harward Medical School, in
                  providing quality primary health care in rural areas
                </P>
                <P>
                  In the past, or organisation has provided supplementary
                  nutrition to childrens between age of 1-5 years in over 200
                  villages.
                </P>
                <P>
                  During covid pandemic, we has undertaken a waste survey to
                  ensure vaccination and used of masks and has supplied over 2
                  lacs masks in rural areas.
                </P>
                <P>
                  GraminSwasthyaUtprerak Kendra (GSUK) is a not-for-profit
                  clinical establishment (first established in May 2017 in
                  Village Shehrok, Aron Tehsil, District Guna, MP) that
                  envisions a progressive, significant and timely transformation
                  in the fragmented healthcare landscape of India through the
                  design,{" "}
                </P>
                <P>
                  GraminSwasthyaUtprerak Kendra (GSUK) is a not-for-profit
                  clinical establishment (first established in May 2017 in
                  Village Shehrok, Aron Tehsil, District Guna, MP) that
                  envisions a progressive, significant and timely transformation
                  in the fragmented healthcare landscape of India through the
                  design, creation, and strengthening of 'smart' community
                  health systems, especially in the rural settings of the
                  country. It seeks to improve, in particular, the delivery of
                  primary care using systems thinking and a three-pronged
                  approach that entails the integration of force-multiplying
                  scalable technology tools, upskilled non-physician healthcare
                  workforce, and evidence-based health management decision
                  pathways or algorithms; all of those intricately weaved into a
                  single model of care. At GSUK, the team firmly roots for
                  access to safe and quality healthcare as a basic human right
                  and, therefore, we strongly advocate universal effective
                  health coverage as being one of the high-priority sustainable
                  development goals. Beyond the public health domain, our
                  interest also lies in exploring the related areas that
                  directly impact the healthcare system. Ultimately, the overall
                  intent is to nurture a culture of good health through the
                  pursuit of a more holistic scheme.{" "}
                </P>
                <P>
                  We operate day care centers in 216 villages of Madhya Pradesh
                  where over 6,000 children are given one meal daily, 365 days a
                  year. Over 500 women are employed who work with local
                  government support like Anganwadi workers, ANM (Auxiliary
                  Nursing and Midwifery) and ASHA workers to provide primary
                  healthcare to children and pregnant mothers. In 2015, we
                  partnered with Optimized Health, a Boston based NGO to run a
                  health clinic in Guna District. We have received funding of
                  $100,000 from Seattle based NGO, Construction for Change to
                  construct the building. We aim to start the clinic by end of
                  2016.
                </P>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="section-team">
        <Container>
          <div className="d-health">
            <H2>Health Care</H2>
            <H6>Need help For Treatment</H6>
            <div className="line"></div>
          </div>
          <Row>
            <Col md={4}>
              <NavLink onClick={() => setLgShow(true)} className="drs-text">
                <div class="single-person">
                  <div class="person-image">
                    <img src={Sanket} alt="" />
                    <span class="sc-pNWdM noIm icon">
                      <BsChatLeftText />
                    </span>
                  </div>
                  <div class="person-info">
                    <h3 class="sc-dlnjwi kWiMr full-name">Dr.Sanket Joshi</h3>
                  </div>
                </div>
              </NavLink>
            </Col>
            <Col md={4}>
              <NavLink onClick={() => setLg1Show(true)} className="drs-text">
                <div class="single-person">
                  <div class="person-image">
                    <img src={Dhillon} alt="" />
                    <span class="sc-pNWdM noIm icon">
                      <BsChatLeftText />
                    </span>
                  </div>
                  <div class="person-info">
                    <h3 class="sc-dlnjwi kWiMr full-name">
                      Dr. Ranu S. Dhillon
                    </h3>
                  </div>
                </div>
              </NavLink>
            </Col>
            <Col md={4}>
              <NavLink onClick={() => setLg2Show(true)} className="drs-text">
                <div class="single-person">
                  <div class="person-image">
                    <img src={Adrish} alt="" />
                    <span class="sc-pNWdM noIm icon">
                      <BsChatLeftText />
                    </span>
                  </div>
                  <div class="person-info">
                    <h3 class="sc-dlnjwi kWiMr full-name">Dr. Adrish Bhadra</h3>
                  </div>
                </div>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="about-area grey-bg2 pos-rel pb-20">
        <Container>
          <Row>
            <Col md={12}>
              <div className="sc-text">
                <H2>Each packet’s nutriAonal content is as per ICDS norms </H2>
              </div>
            </Col>
            <Lightboxcode2 />
          </Row>
        </Container>
      </section>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{
          backgroundColor: "#f2ba13",
          borderColor: " 1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <H2>Dr Sanket Joshi </H2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <P className="mdol-text">
            Sanket hails from Kashipur, Uttarakhand. At present, alongside with
            his work as an independent consultant for Optimize Health Inc.,
            (United States) to help direct administrative & other support
            services in India, and as a Director at
            GraminSwasthyaUtprerakSansthan (A Not-for-Profit section 8 Company,
            India), Sanket is also pursuing a Masters degree in Business Laws
            from National Law School of India University (Bengaluru). After
            graduating from Graphic Era University with a B.Tech. degree in
            Mechanical Engineering in 2014, Sanket spent some time exploring the
            automobile industry and the education sector before finally
            venturing into the healthcare domain. His diverse educational and
            professional experiences bring to the table, a unique skillset. He
            is strongly driven by the untiring desire to create a positive
            social impact that reflects in his commitment to engage, on a
            full-time basis, in grassroots- level work in the development sector
            and lead teams, on the ground, that seek to bring about meaningful
            changes in the underserved population pockets of the country,
            despite the odds involved.
          </P>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={lg2Show}
        onHide={() => setLg2Show(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{
          backgroundColor: "#f2ba13",
          borderColor: " 1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <H2>Dr. Adrish Bhadra </H2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <P className="mdol-text">
            Adrish is from New Delhi and completed his medical training there at
            Maulana Azad Medical College (Delhi University) & Associated
            Hospitals, graduating with an M.B.B.S. degree in 2014. Subsequently,
            he served as a Junior Resident Doctor (Non-Academic) in the
            Department of Medicine at Lok Nayak Hospital (Delhi). In January
            2016, he moved to Raghogarh (Guna District, Madhya Pradesh) to help
            Optimize Health, Inc. set up a rural primary healthcare delivery
            model in Guna – a pilot project that he has been extensively
            involved with, right from the early stages of conception of the idea
            – and manage the entire spectrum of clinical operations. Adrish has,
            since, worked in the capacity of an independent consultant for
            Optimize Health. He is also one of the Directors for
            GraminSwasthyaUtprerakSansthan(A Not-for-Profit section 8 Company,
            India) . His interest lies primarily in public health and policy
          </P>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={lg1Show}
        onHide={() => setLg1Show(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{
          backgroundColor: "#f2ba13",
          borderColor: " 1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <H2>Dr. Ranu S. Dhillon</H2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <P className="mdol-text">
            Ranu Dhillon has spent more than 15 years mainly focusing on
            developing community-based primary health systems in low and
            middle-income countries; and managing epidemics. Based in the
            Division of Global Health Equity at Brigham and Women’s Hospital, he
            is a Senior Health Advisor with the Earth Institute at Columbia
            University, an Associate Member of Ariadne Labs, and teaches at
            Harvard Medical School and Columbia’s School of International and
            Public Affairs. Dr. Dhillon works on building integrated primary and
            community-based approaches for enhancing population health and on
            improving the performance of national health systems. In Rwanda, he
            led the establishment of a comprehensive rural health system for
            25,000 people. He has advised national governments in Liberia,
            Nigeria, and India on building effective healthcare at scale through
            strengthening government health systems and policy reform. With the
            President's Office in Nigeria, he helped create and execute a
            program that guides districts on health strategy and allocates
            additional funding. Dr. Dhillon anchored a collaboration with the
            Nigerian government to create a national community health worker
            program that is being scaled up nationally. In India, he works with
            state governments to improve the delivery of maternal and child
            healthcare through public health systems. Dr. Dhillon served as
            Special Advisor to the President of Guinea for Ebola which involved
            working side-by-side with the country's National Ebola Coordinator
            to develop the strategy and organizational structure for controlling
            the epidemic as well as managing its day-to-day execution.
            Subsequently, worked on strategies to contain outbreaks of Zika in
            the United States and Ebola in the Democratic Republic of Congo. He
            sits on the International Advisory Panel to the National Rural
            Health Mission in India, the Ministerial Working Group for Ministers
            of Health from Nigeria, India, China, Ethiopia, and Kenya, Partners
            in Health’s Community Health Worker Taskforce, and the Board of
            Directors for Spark MicroGrants. He has contributed to the Harvard
            Global Health Delivery Project and served on expert panels with the
            WHO and UNICEF. Dr. Dhillon earned his BS and MD from the combined
            six-year accelerated program at Penn State University and Jefferson
            Medical College and is a graduate of the Hiatt Residency for Global
            Health Equity and Internal Medicine at Brigham and Women’s Hospital
            and Harvard Medical School.
          </P>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Health;
