import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table,} from "react-bootstrap";
import { H2, H1, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
function Report() {
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Annual Reports</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Annual Reports</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <section className="annu-text">
        <Container>
          <Row>
            <Col md={10} className="offset-md-1">
              <div className="annu-1">
                <H1>Annual Reports</H1>
                <P>
                  When you donate money to the Children’s Hospital Trust, we
                  like to show you, clearly and openly (not to mention with
                  pride and excitement), the difference that you help make in
                  children’s lives. We’re dedicated to transparency and feel
                  it’s important to have all our information available to you.
                </P>
              </div>
              <Table class="table table-bordered">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Year</th>
                    <th> Download Report</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>2018</td>
                    <td>
                      <a href="https://www.childrenshospitaltrust.org.za/wp-content/uploads/2021/08/AR2020_190721.pdf">
                        <BsFillFileEarmarkPdfFill
                          color="red"
                          style={{ fontSize: "x-large" }}
                          className="pdf"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>2019</td>
                    <td>
                      <a href="https://www.childrenshospitaltrust.org.za/wp-content/uploads/2021/08/AR2020_190721.pdf">
                        <BsFillFileEarmarkPdfFill
                          color="red"
                          style={{ fontSize: "x-large" }}
                          className="pdf"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>2020</td>
                    <td>
                      <a href="https://www.childrenshospitaltrust.org.za/wp-content/uploads/2021/08/AR2020_190721.pdf">
                        <BsFillFileEarmarkPdfFill
                          color="red"
                          style={{ fontSize: "x-large" }}
                          className="pdf"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>2021</td>
                    <td>
                      <a href="https://www.childrenshospitaltrust.org.za/wp-content/uploads/2021/08/AR2020_190721.pdf">
                        <BsFillFileEarmarkPdfFill
                          color="red"
                          style={{ fontSize: "x-large" }}
                          className="pdf"
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Report;
