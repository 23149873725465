import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { H2, H4, H1, H6, H5, P, } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import eye from "../../assests/image/eye.jpg";


function Past_project_Details() {

  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Details</H2>
            <ul>
              <li>
                <Link to="/Pastproject">Past project</Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <section className="ress-descript">
        <Container>
          <Row>
            <Col md={10} className="offset-md-1">
              <div className="press-img">
                <img src={eye} alt="" />
              </div>
              <div className="press-1">
                <H1 className="mt-3">Past Projects Details</H1>
                <H4>Gas Authority Of India Limited</H4>
                <P>
                  Gas Authority Of India Limited in Guna is one of the leading businesses in the LPG Filling Stations.
                  Also known for Cooking Gas Agencies, Gas Agencies, Project Work, LPG Conversion Kit Dealers-Lovato,
                  LPG Conversion Kit Installation Services, LPG Conversion Kit Distributors, LPG Filling Stations,
                  Real Time Project BE and much more. Gas Authority Of India Limited, Guna
                </P>
                <div className="projectplan-text">
                  <H5>Bio Gas Plant</H5>
                  <P>
                    As a part of our Prime Ministers vision to increase farmers'
                    income to double by the year 2022, Banas Dairy have taken many
                    initiatives as described above including multi beneficial
                    Biogas project.
                  </P>
                  <H5>Project Objective</H5>
                  <P>Additional income to Farmers</P>
                  <P>Waste to wealth</P>
                  <P>Hygiene & Environment</P>
                  <P>Nutrient rich fertilizer</P>
                  <P>Clean Energy</P>
                  <P>Employment</P>
                </div>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </>
  );
}

export default Past_project_Details;
