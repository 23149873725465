import React, { useState } from "react";
import "./work.css";
import Lightboxcode from "../Work/Lightboxcode";
import { Link } from "react-router-dom";
import { Container, NavLink, Row, Col, Modal } from "react-bootstrap";
import {
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  Span,
} from "../../components/Typography/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
// vinay
import vinay from "../../assests/image/vinay/vinay.jpeg";
import v1 from "../../assests/image/vinay/v1.jpg";
import v2 from "../../assests/image/vinay/v2.jpg";
import v3 from "../../assests/image/vinay/v3.jpg";
import v4 from "../../assests/image/vinay/v4.jpg";
import v5 from "../../assests/image/vinay/v5.jpg";
import v6 from "../../assests/image/vinay/v6.jpg";
import v7 from "../../assests/image/vinay/v7.jpg";
import v8 from "../../assests/image/vinay/v8.jpg";
// raju
import r2 from "../../assests/image/raju/r2.jpg";
import r3 from "../../assests/image/raju/r3.jpg";
import r5 from "../../assests/image/raju/r5.jpg";
import r4 from "../../assests/image/raju/r4.jpg";
import r6 from "../../assests/image/raju/r6.jpg";
import r7 from "../../assests/image/raju/r7.jpg";
import r8 from "../../assests/image/raju/r8.jpg";
import r9 from "../../assests/image/raju/r9.jpg";
import r19 from "../../assests/image/raju/r19.jpg";
import ART1 from "../../assests/image/raju/ART1.jpg";
import ART2 from "../../assests/image/raju/ART2.jpg";
import ART3 from "../../assests/image/raju/ART3.jpg";
import ART4 from "../../assests/image/raju/ART4.jpg";
import ART5 from "../../assests/image/raju/ART5.jpg";
import ART6 from "../../assests/image/raju/ART6.jpg";
import ART7 from "../../assests/image/raju/ART7.jpg";
import ART8 from "../../assests/image/raju/ART8.jpg";
import ART9 from "../../assests/image/raju/ART9.jpg";
import ART10 from "../../assests/image/raju/ART10.jpg";
import ART11 from "../../assests/image/raju/ART11.jpg";
import ART12 from "../../assests/image/raju/ART12.jpg";
import ART13 from "../../assests/image/raju/ART13.jpg";
import ART14 from "../../assests/image/raju/ART14.jpg";
import ART15 from "../../assests/image/raju/ART15.jpg";
import ART16 from "../../assests/image/raju/ART16.jpg";
import ART17 from "../../assests/image/raju/ART17.jpg";
import ART18 from "../../assests/image/raju/ART18.jpg";
import ART19 from "../../assests/image/raju/ART19.jpg";
import km1 from "../../assests/image/raju/km1.png";
import km2 from "../../assests/image/raju/km2.png";
import km3 from "../../assests/image/raju/km3.png";
import km4 from "../../assests/image/raju/km4.jpg";
// hemant
import hemant from "../../assests/image/hemant/hemant.jpg";
import r12 from "../../assests/image/hemant/r12.jpg";
import r13 from "../../assests/image/hemant/r13.jpg";
import r14 from "../../assests/image/hemant/r14.jpg";
import r21 from "../../assests/image/hemant/r21.jpg";

// deepak
import r56 from "../../assests/image/deepak/r56.jpg";
import m1 from "../../assests/image/deepak/m1.jpg";
import m2 from "../../assests/image/deepak/m2.jpg";
import m3 from "../../assests/image/deepak/m3.jpg";
import m4 from "../../assests/image/deepak/m4.jpg";
import m5 from "../../assests/image/deepak/m5.jpg";
import m6 from "../../assests/image/deepak/m6.jpg";
import m9 from "../../assests/image/deepak/m9.jpg";
import m17 from "../../assests/image/deepak/m17.jpg";
import m20 from "../../assests/image/deepak/m20.jpg";
import m24 from "../../assests/image/deepak/m24.jpg";
import m27 from "../../assests/image/deepak/m27.jpg";
import m28 from "../../assests/image/deepak/m28.jpg";
import m29 from "../../assests/image/deepak/m29.jpg";
// student
import student from "../../assests/image/student/student.jpg";
import p1 from "../../assests/image/student/p1.jpg";
import p2 from "../../assests/image/student/p2.jpg";
import p3 from "../../assests/image/student/p3.jpg";
import p4 from "../../assests/image/student/p4.jpg";
import p5 from "../../assests/image/student/p5.jpg";
import p6 from "../../assests/image/student/p6.jpg";
import p7 from "../../assests/image/student/p7.jpg";
import p8 from "../../assests/image/student/p8.jpg";
import p10 from "../../assests/image/student/p10.jpg";
import p11 from "../../assests/image/student/p11.jpg";
import p12 from "../../assests/image/student/p12.jpg";
import p13 from "../../assests/image/student/p13.jpg";
import p14 from "../../assests/image/student/p14.jpg";
import p15 from "../../assests/image/student/p15.jpg";
import p16 from "../../assests/image/student/p16.jpg";
import p17 from "../../assests/image/student/p17.jpg";
import p18 from "../../assests/image/student/p18.jpg";
import p19 from "../../assests/image/student/p19.jpg";
import p20 from "../../assests/image/student/p20.jpg";
import p21 from "../../assests/image/student/p21.jpg";
import p22 from "../../assests/image/student/p22.jpg";
import p23 from "../../assests/image/student/p23.jpg";
import p24 from "../../assests/image/student/p24.jpg";
import p26 from "../../assests/image/student/p26.jpg";
import p27 from "../../assests/image/student/p27.jpg";
import p28 from "../../assests/image/student/p28.jpg";
import p29 from "../../assests/image/student/p29.jpg";
import p30 from "../../assests/image/student/p30.jpg";
import p31 from "../../assests/image/student/p31.jpg";
import p32 from "../../assests/image/student/p32.jpg";
import p33 from "../../assests/image/student/p33.jpg";
import p34 from "../../assests/image/student/p34.jpg";
import p35 from "../../assests/image/student/p35.jpg";
import p36 from "../../assests/image/student/p36.jpg";
import p37 from "../../assests/image/student/p37.jpg";
import p38 from "../../assests/image/student/p38.jpg";
import p39 from "../../assests/image/student/p39.jpg";
import p40 from "../../assests/image/student/p40.jpg";
import p41 from "../../assests/image/student/p41.jpg";
import p42 from "../../assests/image/student/p42.jpg";
import p43 from "../../assests/image/student/p43.jpg";
import p44 from "../../assests/image/student/p44.jpg";
import p45 from "../../assests/image/student/p45.jpg";
import p46 from "../../assests/image/student/p46.jpg";
import p47 from "../../assests/image/student/p47.jpg";
import p48 from "../../assests/image/student/p48.jpg";
import p49 from "../../assests/image/student/p49.jpg";
import p50 from "../../assests/image/student/p50.jpg";
import p51 from "../../assests/image/student/p51.jpg";
import p52 from "../../assests/image/student/p52.jpg";
import p53 from "../../assests/image/student/p53.jpg";
import p54 from "../../assests/image/student/p54.jpg";
import p56 from "../../assests/image/student/p56.jpg";
import p57 from "../../assests/image/student/p57.jpg";
import p58 from "../../assests/image/student/p58.jpg";
import p59 from "../../assests/image/student/p59.jpg";
import p60 from "../../assests/image/student/p60.jpg";
import p61 from "../../assests/image/student/p61.jpg";
import p62 from "../../assests/image/student/p62.jpg";
import p63 from "../../assests/image/student/p63.jpg";
import p64 from "../../assests/image/student/p64.jpg";
import p65 from "../../assests/image/student/p65.jpg";
import p66 from "../../assests/image/student/p66.jpg";
import p67 from "../../assests/image/student/p67.jpg";
import p68 from "../../assests/image/student/p68.jpg";
import p72 from "../../assests/image/student/p72.jpg";
import p73 from "../../assests/image/student/p73.jpg";
import p75 from "../../assests/image/student/p75.jpg";
import p76 from "../../assests/image/student/p76.jpg";
import p77 from "../../assests/image/student/p77.jpg";
import p78 from "../../assests/image/student/p78.jpg";
import p79 from "../../assests/image/student/p79.jpg";
import p80 from "../../assests/image/student/p80.jpg";
import p81 from "../../assests/image/student/p81.jpg";
import p82 from "../../assests/image/student/p82.jpg";
import p83 from "../../assests/image/student/p83.jpg";
import p84 from "../../assests/image/student/p84.jpg";
import traing from "../../assests/image/banner/traing.jpg";
import trainprincipa from "../../assests/image/team/trainprincipa.jpg";
import modal1 from "../../assests/image/modal1.jpg";
import electrician from "../../assests/image/electrician.jpg";
import welder from "../../assests/image/welder.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Skill_Development() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [xxlShow, setXxlShow] = useState(false);
  const [xxl1Show, setXxl1Show] = useState(false);
  const [xxl2Show, setXxl2Show] = useState(false);
  const [xxl3Show, setXxl3Show] = useState(false);
  const [xxl4Show, setXxl4Show] = useState(false);
  const [lg5Show, setLg5Show] = useState(false);
  const [lg6Show, setLg6Show] = useState(false);
  const [lg7Show, setLg7Show] = useState(false);
  return (
    <>
      {/* <EnergystyleStyled> */}
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one skills">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt_en">
              <div className="empt-text">
                <H4>Skill Development</H4>
              </div>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Span>Skill Development</Span>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <section className="tf-traxt">
        <Container>
          <div className="d-health">
            <H2>Training Center</H2>
            <H6>Hindupat ITI (Pvt.) </H6>
            <div className="line"></div>
          </div>
          <div className="details-jhulaghar">
            <Row>
              <Col md={12}>
                <P className="mt-3">
                  Hindupat ITI (Pvt.) is situated in Guna Madhya Pradesh have
                  ITI trade Computer Operator and Programming Assistant (COPA),
                  Electrician, Welder. Hindupat ITI (Pvt.) has been established
                  in the year 05-Jan-2015 Best Industrial Training Institute in
                  Guna is Hindupat ITI (Pvt.). MISSION:-The primary target of
                  our ITI is to train its candidates for the industry and to
                  make them ready for work.our ITI also conducts apprenticeship
                  courses to make this practicable. The Industrial Training
                  Institutes are established by the DGET (Directorate-General
                  for Employment and Training), the Ministry of Skills
                  Development and Entrepreneurship, and the Union Government to
                  offer training in multiple trades. Many ITIs are located in
                  India, both Governmental and private that provide technical
                  and vocational training to students. The aspirants have to
                  appear for the All-India Trade Test (AITT). After the National
                  Trade Certificate (NTC) is issued to qualified candidates. The
                  training is offered for 130 trades that include 80 engineering
                  and 50 non-engineering trades.
                </P>
              </Col>
            </Row>
          </div>
          <div className="details-jhulaghar">
            <Row>
              <Col md={4}>
                <div className="trade-text">
                  <img src={trainprincipa} alt="" />
                </div>
              </Col>
              <Col md={8}>
                <H3 className="mt-3">Mr.Ajay Chauhan </H3>
                <P className="chohan-text">
                  HINDUPAT PVT. ITI PRINCIPLE MR.AJAY CHAUHAN HAVE BEEN JOIND
                  THE INSTITUTION ON 2018 AS THE HEAD OF THE INSTUTION AND HE
                  WILL ALSO A TEACHER OF A SUBJECT( ENGINEERING DRAWING) AND
                  HAVING VERY HIGH QULIFICATION OF TEACHING AND LEADERSHIP
                  QUALITY. EDUQUALIFICATION :- BE.MECH, M.TECH(THERMAL*)
                </P>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <div className="details-item">
        <Container>
          <Row>
            <Col md={12}>
              <div className="details-jhulaghar">
                <H5>Introduction Of Art School - Raghogarh</H5>
                <P>
                  Raghogarh was a princely state of ancient India, a small
                  region of Malwa was a state where the art loving rulers ruled.
                  It is situated 57.92 kms. in the north-west from Sironj. It is
                  situated 24º/27º longitude in the north and 77º/14º latitude.
                  This small riyasat was having 290 sq.kms. Area of its
                  domination.
                </P>
                <P>
                  As Raghogarh was nearer to the borders of Kota & Bundi, the
                  social and cultural trends of both these states influenced the
                  traditions of Raghogarh. The eastern part of this Riyasat was
                  known as jungle and the western part was full of small
                  hills.Therefore, the rulers of Raghogarh were fond of hunting
                  and we have fine miniatures in which we find the rulers
                  engaged in the hunting activity.
                </P>
                <P>
                  The Khichi Chouhans of Raghogarh are associated with the
                  Sisodia clan of Mewar. They have their glorious past and they
                  are known as the direct descendants of Raja Prithviraj
                  Chouhan, a great warrior who strongly fought against the
                  Muslim invasions. Khichis are Chouhans who are having several
                  branches
                </P>
              </div>
              <div className="hideshow-text">
                <Row>
                  <Col md={12}>
                    <div className="">
                      <div className="details-jhulaghar">
                        <H5>Skill Development</H5>
                        <P className="mt-2">
                          To provide employment opportunities for the unemployed
                          youth, we have started the Hindupat Institute of Skill
                          Development (HISD). We offer courses in computer
                          training, fire fighting, security guard training,
                          electrician training and welding training. Currently
                          the institute has the short term National Council for
                          Vocational Training (NCVT) affiliation. To improve
                          employment opportunities for skilled youth, we have
                          established a dedicated placement cell that links
                          skilled workers with companies that are looking for
                          employees in the related sector.{" "}
                        </P>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="trade-text">
                <H6> TRADES AVAILABLE IN OUR INSTITUTE</H6>
                <Row>
                  <Col md={4}>
                    <div className="trasd9-text gr">
                      <NavLink className="trnav-text">
                        <H3>COPA</H3>
                        <p>
                          Raghogarh was a princely state of ancient India, a
                          small region of Malwa
                        </p>
                        <NavLink
                          className="trnav-text22"
                          onClick={() => setLg5Show(true)}
                        >
                          Read More
                        </NavLink>
                      </NavLink>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="trasd9-text yl">
                      <NavLink className="trnav-text">
                        <H3> Electrician</H3>
                        <p>
                          Engineering ITI trade Electrician is powered by NCVT.
                        </p>
                        <NavLink
                          className="trnav-text22"
                          onClick={() => setLg6Show(true)}
                        >
                          Read More
                        </NavLink>
                      </NavLink>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="trasd9-text or">
                      <NavLink className="trnav-text">
                        <H3>Welder</H3>
                        <p>
                          Trade Type: Engineering ITI trade Welder is powered by
                          NCVT.
                        </p>
                        <NavLink
                          className="trnav-text22"
                          onClick={() => setLg7Show(true)}
                        >
                          Read More
                        </NavLink>
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        size="lg"
        show={lg5Show}
        onHide={() => setLg5Show(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ backgroundColor: "#5aa411" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Trade: Computer Operator and Programming Assistant (COPA)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mdk">
            <img src={modal1} alt="" />
            <H3>Syllabus: </H3>
            <H6>
              Syllabus for ITI Trade Computer Operator and Programming Assistant
              (COPA)
            </H6>
            <H6>
              IT-ITeS Trades Duration: <Span>One year</Span>{" "}
            </H6>
            <H6>
              Minimum Qualification Eligibility:{" "}
              <Span> 10th Passed under 10+2 System</Span>
            </H6>
            <P>Trade Type: Non Engineering ITI trade Computer Operator and
            Programming Assistant (COPA) is powered by NCVT. ITI trade Computer
            Operator and Programming Assistant (COPA) is a job oriented trade
            ITI trade Computer Operator and Programming Assistant (COPA) is
            suitable for government job and private job. This ITI trade Computer
            Operator and Programming Assistant (COPA) is very powerful for
            self-empowerment. This ITI trade Computer Operator and Programming
            Assistant (COPA) is perfectly design to fulfill industrial
            requirement of Indian Industries as well as International
            industries. The individual can work as both Computer Operator/
            Programming Assistant; operates computer and peripheral equipment to
            process business, scientific, engineering, or other data, according
            to operating instructions. As programming assistant installs,
            maintains and updates computer programs by making minor changes and
            adjustments to them under the guidance of computing professionals.
            Maintains and updates documents of computer programs and
            installations with assistance under supervision of supervisor</P>
            <ul>
              {" "}
              On successful completion of this course the candidates shall be
              gainfully employed as:
            </ul>
            <li>Computer Operator</li>
            <li> Programming Assistant</li>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={lg6Show}
        onHide={() => setLg6Show(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ backgroundColor: "#f2ba13" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Trade: Electrician
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mdk">
            <img src={electrician} alt="" />
            <H3>Syllabus: </H3>
            <H6>Syllabus for ITI Trade Electrician Power</H6>
            <H6>
              Trades Duration: <Span>Two Year</Span>{" "}
            </H6>
            <H6>
              Minimum Qualification Eligibility
              <Span>10th Passed under 10+2 System with Science &Maths</Span>
            </H6>
            <P>Trade Type: Engineering ITI trade Electrician is powered by NCVT.
            ITI trade Electrician is a job oriented trade ITI trade Electrician
            is suitable for government job and private job. This ITI trade
            Electrician is very powerful for self-empowerment. This ITI trade
            Electrician is perfectly design to fulfill</P>
            <ul>
              industrial requirement of Indian Industries as well as
              International industries.
            </ul>
            <li>
              Electricians have a wide scope of Employability ranging from
              self-employment, contractual employment to Industrial jobs.{" "}
            </li>
            <li>
              After successful completion of this course, Electricians can
              aspire to become Electrical Contractors by acquiring the ‘B”
              licence from the Electrical Licence Board.{" "}
            </li>
            <li>
              They can set up their own Rewinding and servicing of Domestic
              Equipment shop
            </li>
            <li>
              Job opportunities are wide open in Defence, Railways, Transport,
              Ship Building, Electricity Board, various Industries etc
            </li>
            <li>
              They can also go for further higher studies after successful
              completion of course.
            </li>
            <li>
              Students who have completed this course have found employment in
              the following areas:
            </li>
            <li>
              Service/Maintenance Technician for domestic appliances in Reputed
              Companies
            </li>
            <li>Winder of Electrical Motors in winding shop</li>
            <li>Contractor for domestic wiring and industrial wiring
            Armature winder of Electrical fans and motors
            </li>
           
            <li>Electrical appliance repair in electrical shops</li>
            <li>
              Indian Railway (Asst. Driver, Tech. Gr. III, Appr. Technician)
            </li>
            <li> Local Electricity Board</li>
            <li>Assembler of Electrical control Gears</li>
            <li>Telephone Department</li>
            <li>
              {" "}
              Installation and Testing division of Auditorium and Cinema Hall
            </li>
            <li> Factories</li>
            <li>As Instructor in Govt./Private ITI/ITC</li>
            <li>Merchant Navy</li>
            <li>Indian Air Force</li>
            <li>Self-Employment in Service Centre</li>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={lg7Show}
        onHide={() => setLg7Show(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{
          backgroundColor: "#09c8e1",
          borderColor: " 1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Trade: Welder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mdk">
            <img src={welder} alt="" />
            <H3>Syllabus: </H3>
            <H6>Syllabus for ITI Trade Welder Capital Goods & Manufacturing</H6>
            <H6>
              Trades Duration: <Span>One year</Span>{" "}
            </H6>
            <H6>
              Minimum Qualification Eligibility
              <Span>10th Passed under 10+2 System</Span>
            </H6>
           <P>Trade Type: Engineering ITI trade Welder is powered by NCVT. ITI
            trade Welder is a job oriented trade ITI trade Welder is suitable
            for government job and private job. This ITI trade Welder is very
            powerful for self-empowerment. This ITI trade Welder is perfectly
            design to fulfill industrial requirement of Indian Industries as
            well as International industries. Gas welding (includes gas
            cutting), Arc welding, TIG welding, MIG/MAG welding etc</P> 
          </div>
        </Modal.Body>
      </Modal>
      <section className="training-text">
        <Container>
          <div className="d-health mt-3">
            <H6>Hindupat computer training institute </H6>
            <div className="line"></div>
          </div>
          <Row>
            <Col lg={6}>
              <div className="train-text">
                <img src={traing} alt="" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="trd-text">
                <div className="train1-taxt">
                  <H2>HINDUPAT COMPUTER TRAINING INSTITUTE RUNNING COURSES</H2>
                  <H5>
                    MAKHANLAL CHATURVEDI UNIVERSITY BHOPAL MADHYA PRADESH{" "}
                  </H5>
                  <P>
                    1- DCA (DIPLOMA IN COMPUTER APPLICATION – 12TH PASS WITH 1
                    YEAR)
                  </P>
                  <P>
                    2- PGDCA (POST GRADUATION DIPLOMA IN COMPUTER APPLICATION –
                    GRADUATION ANY SUBJECT WITH 1 YEAR )
                  </P>
                  <H5>
                    NATIONAL COUNCIAL FOR PRAMOTATION OF URDU LANGUAGE DELHI{" "}
                  </H5>
                  <P>
                    1- CABA (COMPUTER APPLICATION AND BUSINESS ACCOUNTING – 10TH
                    PASS WITH 1 YEAR)
                  </P>
                  <P>2- TALLY ( 10TH PASS- WITH 6 MONTH)</P>
                  <P>3- BASIC COMPUTER COURSE ( 8TH PASS WITH 6 MONTH )</P>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="compte-text">
        <Container>
          <div className="d-health mt-3">
            <H2>Arts</H2>
            <H6>School of Arts</H6>
            <div className="line"></div>
          </div>

          <Row>
            <Col lg={4}>
              <div className="team-member mt-3">
                <img alt="" src={vinay} />
                <div className="mem">
                  <NavLink
                    onClick={() => setXxlShow(true)}
                    className="viw-port"
                  >
                    View Portfolio
                  </NavLink>
                  <H4>Vinaykant Rao</H4>
                </div>
                {/* <CloseButton disabled /> */}
                <Modal
                  size="xxl"
                  show={xxlShow}
                  onHide={() => setXxlShow(false)}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showArrows={true}
                    showThumbs={false}
                    showIndicators={false}
                    interval="1500"
                    transitionTime="500"
                  >
                    <div className="sky-img">
                      <img alt="" src={v1} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={v2} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={v3} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={v4} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={v5} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={v6} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={v7} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={v8} />
                    </div>
                  </Carousel>
                </Modal>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-member mt-3">
                <img alt="" src={r19} />
                <div className="mem">
                  <NavLink
                    onClick={() => setXxl1Show(true)}
                    className="viw-port"
                  >
                    View Portfolio
                  </NavLink>
                  <H4>Raju saini</H4>
                </div>
                <Modal
                  size="xxl"
                  show={xxl1Show}
                  onHide={() => setXxl1Show(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                  style={{ backgroundColor: "transparent" }}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Carousel
                    autoPlay={true}
                    // infiniteLoop={true}
                    // showArrows={false}
                    showThumbs={false}
                    showIndicators={false}
                    // interval='1500'
                    // transitionTime='500'
                  >
                    <div className="sky-img">
                      <img alt="" src={r2} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r3} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r4} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r5} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r6} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r7} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r8} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r9} />
                    </div>

                    <div className="sky-img">
                      <img alt="" src={ART1} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART2} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART3} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART4} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART4} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART5} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART6} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART7} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART8} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART9} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART10} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART11} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART12} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART13} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART14} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART15} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART16} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART17} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART18} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={ART19} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={km1} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={km2} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={km3} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={km4} />
                    </div>
                  </Carousel>
                </Modal>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-member mt-3">
                <img alt="" src={hemant} />
                <div className="mem">
                  <NavLink
                    onClick={() => setXxl2Show(true)}
                    className="viw-port"
                  >
                    View Portfolio
                  </NavLink>
                  <H4>Hemant Rao</H4>
                </div>
                <Modal
                  size="xxl"
                  show={xxl2Show}
                  onHide={() => setXxl2Show(false)}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Carousel
                    autoPlay={true}
                    // infiniteLoop={true}
                    // showArrows={false}
                    showThumbs={false}
                    showIndicators={false}
                    // interval='1500'
                    // transitionTime='500'
                  >
                    <div className="sky-img">
                      <img alt="" src={r12} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r13} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r14} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={r21} />
                    </div>
                  </Carousel>
                </Modal>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-member mt-3">
                <img alt="" src={r56} />
                <div className="mem">
                  <NavLink
                    onClick={() => setXxl3Show(true)}
                    className="viw-port"
                  >
                    View Portfolio
                  </NavLink>
                  <H4>Deepak Sharma</H4>
                </div>
                <Modal
                  size="xxl"
                  show={xxl3Show}
                  onHide={() => setXxl3Show(false)}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Carousel
                    autoPlay={true}
                    // infiniteLoop={true}
                    // showArrows={false}
                    showThumbs={false}
                    showIndicators={false}
                    // interval='1500'
                    // transitionTime='500'
                  >
                    <div className="sky-img">
                      <img alt="" src={m1} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={m2} />
                    </div>

                    <div className="sky-img">
                      <img alt="" src={m3} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={m4} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={m5} />
                    </div>

                    <div className="sky-img">
                      <img alt="" src={m6} />
                    </div>

                    <div className="sky-img">
                      <img alt="" src={m9} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={m17} />
                    </div>

                    <div className="sky-img">
                      <img alt="" src={m20} />
                    </div>

                    <div className="sky-img">
                      <img alt="" src={m24} />
                    </div>

                    <div className="sky-img">
                      <img alt="" src={m27} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={m28} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={m29} />
                    </div>
                  </Carousel>
                </Modal>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-member mt-3">
                <img alt="" src={student} />
                <div className="mem">
                  <NavLink
                    onClick={() => setXxl4Show(true)}
                    className="viw-port"
                  >
                    View Portfolio
                  </NavLink>
                  <H4>Student Painting</H4>
                </div>
                <Modal
                  size="xxl"
                  show={xxl4Show}
                  onHide={() => setXxl4Show(false)}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Carousel
                    autoPlay={true}
                    // infiniteLoop={true}
                    // showArrows={false}
                    showThumbs={false}
                    showIndicators={false}
                    // interval='1500'
                    // transitionTime='500'
                  >
                    <div className="sky-img">
                      <img alt="" src={p1} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p2} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p3} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p4} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p5} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p6} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p7} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p8} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p10} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p11} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p12} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p13} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p14} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p15} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p16} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p17} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p18} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p19} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p20} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p21} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p22} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p23} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p24} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p26} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p27} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p28} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p29} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p30} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p31} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p32} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p33} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p34} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p35} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p36} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p37} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p38} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p39} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p40} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p41} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p42} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p43} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p44} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p45} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p46} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p47} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p48} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p49} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p50} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p51} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p52} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p53} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p54} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p56} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p57} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p58} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p59} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p60} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p61} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p62} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p63} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p64} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p65} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p66} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p67} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p68} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p72} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p73} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p75} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p76} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p77} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p78} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p79} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p80} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p81} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p82} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p83} />
                    </div>
                    <div className="sky-img">
                      <img alt="" src={p84} />
                    </div>
                  </Carousel>
                </Modal>
              </div>
            </Col>
          </Row>
          <section className="compte-text">
            <Lightboxcode />
          </section>
        </Container>
      </section>
    </>
  );
}

export default Skill_Development;
