import React from "react";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import { H2, P, Span } from "../../components/Typography/index";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import donation2 from "../../assests/image/donate/donation2.jpg";
import "./allPages.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
function Taq() {
 
  return (
    <>
      <Navbar />
      <div className="page-title-area title-bg-ty">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Taq</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Taq</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <section className="team-area four pt-100 pb-70">
        <Container>
          <Row>
            <Col lg={6}>
              <img src={donation2} alt="Donation" className="faq-img" />
            </Col>
            <Col lg={6}>
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I help to charity?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <P>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniaquis nostrud ullamco nisi ut aliquip
                    </P>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I join your team?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <P>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniaquis nostrud ullamco nisi ut aliquip
                    </P>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I be a volunteer?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <P>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniaquis nostrud ullamco nisi ut aliquip
                    </P>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      From where should I start to help people?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <P>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniaquis nostrud ullamco nisi ut aliquip
                    </P>
                  </AccordionItemPanel>
                </AccordionItem>

              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Taq;
