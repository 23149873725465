import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { H2, H3, H4,  P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import mr14 from "../../assests/image/mr14.jpg";
import m15 from "../../assests/image/m15.jpg";
import ReactPlayer from "react-player";
import "./Press.css";
function PressReleases() {
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Press Releases</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Press Releases</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <section className="details-item">
      
        <Container>
        <H2>Press Releases</H2>
          <Row>
            <Col md={12}>
              <div className="press-text">
                
                <P>
                  The release should be written on the company letterhead, with
                  the words “Press Release” or “News Release” at the top left
                  corner of the page. Below this, indicate when the information
                  is available for publication. The term “immediate release”
                  means the information is ready to publish and can be used by
                  journalists as soon as they receive it. Occasionally, you
                  might want more time to gather other information, or would
                  prefer that the journalist publish the announcement at a later
                </P>
                
              </div>
            </Col>
          </Row>
          <Card>
            <Card.Header className="right">
              Published At :01/05/2021
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <Row className="mb-4">
                  <Col md={6}>
                    <div className="press-img">
                      <img src={mr14} alt="" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="press-1">
                      <H4>Publishing Press Releases</H4>
                      <P>
                        Writing a press release is really only half the battle.
                        Once you're finished with production, it'll be time to
                        focus on distribution. Of course, we're all familiar
                        with the traditional distribution levers we can pull,
                        which include publishing the press release on our
                        website/blog, as well as sharing the press release with
                        our followers/subscribers via social media and email.
                        But for ensuring a press release gets the maximum amount
                        of distribution possible, here are some tips you can
                        Writing a press release is really only half the battle.
                        Once you're finished with production, it'll be time to
                        focus on distribution. Of course, we're all familiar
                        with the traditional distribution levers we can pull,
                        which include publishing the press release on our
                        website/blog, as well as sharing the press release with
                        our followers/subscribers via social media and email.
                        But for ensuring a press release gets the maximum amount
                        of distribution possible, here are some tips you can
                        Writing a press release is really only half the battle.
                      
                      </P>
                      <Link to="/Press_Releases_Descript/1" onClick={topFunction} className="press-view">View More</Link>
                    </div>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header>Published At :01/05/2021</Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col md={6}>
                    <div className="press-1">
                      <H4>Release Date or Dateline</H4>
                      <P>
                      Writing a press release is really only half the battle.
                        Once you're finished with production, it'll be time to
                        focus on distribution. Of course, we're all familiar
                        with the traditional distribution levers we can pull,
                        which include publishing the press release on our
                        website/blog, as well as sharing the press release with
                        our followers/subscribers via social media and email.
                        But for ensuring a press release gets the maximum amount
                        of distribution possible, here are some tips you can
                        Writing a press release is really only half the battle.
                        Once you're finished with production, it'll be time to
                        focus on distribution. Of course, we're all familiar
                        with the traditional distribution levers we can pull,
                        which include publishing the press release on our
                        website/blog, as well as sharing the press release with
                        our followers/subscribers via social media and email.
                        But for ensuring a press release gets the maximum amount
                        of distribution possible, here are some tips you can
                        Writing a press release is really only half the battle.
                      </P>
                      <Link to="/Press_Releases_Descript/2"  onClick={topFunction} className="press-view">View More</Link>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="press-img">
                      <img src={m15} alt="" />
                    </div>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header className="right">
              Published At :01/05/2021
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col md={6}>
                    <div className="press-4">
                      <ReactPlayer
                        className="react-player"
                        url="https://youtu.be/tFvC9oM3vJc"
                        width="100%"
                        height="300px"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="press-1">
                      <H4>ScribbleLive Acquires New Company</H4>
                      <P>
                      Writing a press release is really only half the battle.
                        Once you're finished with production, it'll be time to
                        focus on distribution. Of course, we're all familiar
                        with the traditional distribution levers we can pull,
                        which include publishing the press release on our
                        website/blog, as well as sharing the press release with
                        our followers/subscribers via social media and email.
                        But for ensuring a press release gets the maximum amount
                        of distribution possible, here are some tips you can
                        Writing a press release is really only half the battle.
                        Once you're finished with production, it'll be time to
                        focus on distribution. Of course, we're all familiar
                        with the traditional distribution levers we can pull,
                        which include publishing the press release on our
                        website/blog, as well as sharing the press release with
                        our followers/subscribers via social media and email.
                        But for ensuring a press release gets the maximum amount
                        of distribution possible, here are some tips you can
                        Writing a press release is really only half the battle.
                      </P>
                      <Link to="/Press_Releases_Descript/3"  onClick={topFunction} className="press-view">View More</Link>
                    </div>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mt-4 mb-4">
            <Card.Header>Published At :01/05/2021</Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col md={6}>
                    <div className="press-1">
                      <H4>ScribbleLive Acquires New Company</H4>
                      <P>
                      Writing a press release is really only half the battle.
                        Once you're finished with production, it'll be time to
                        focus on distribution. Of course, we're all familiar
                        with the traditional distribution levers we can pull,
                        which include publishing the press release on our
                        website/blog, as well as sharing the press release with
                        our followers/subscribers via social media and email.
                        But for ensuring a press release gets the maximum amount
                        of distribution possible, here are some tips you can
                        Writing a press release is really only half the battle.
                        Once you're finished with production, it'll be time to
                        focus on distribution. Of course, we're all familiar
                        with the traditional distribution levers we can pull,
                        which include publishing the press release on our
                        website/blog, as well as sharing the press release with
                        our followers/subscribers via social media and email.
                        But for ensuring a press release gets the maximum amount
                        of distribution possible, here are some tips you can
                        Writing a press release is really only half the battle.
                      </P>
                      <Link to="/Press_Releases_Descript/4"   onClick={topFunction} className="press-view">View More</Link>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="press-4">
                      <ReactPlayer
                        className="react-player"
                        url="https://youtu.be/tFvC9oM3vJc"
                        width="100%"
                        height="300px"
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default PressReleases;
