import React from "react";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import { H2, H3, H4, Span } from "../../components/Typography/index";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import princi1 from "../../assests/image/team/princi1.jpg";
import princi from "../../assests/image/team/princi.png";
import vinay from "../../assests/image/vinay/vinay.jpeg";
import r19 from "../../assests/image/raju/r19.jpg";
import hemant from "../../assests/image/hemant/hemant.jpg";
import r56 from "../../assests/image/deepak/r56.jpg";
import pooja from "../../assests/image/pooja.jpg";
import Adrish1 from "../../assests/image/team/Adrish1.png";
import Dhillon1 from "../../assests/image/team/Dhillon1.jpg";
import saket from "../../assests/image/team/saket.jpg";
function Team() {
  return (
    <>
      <Navbar />
      <div className="page-title-area title-bg-ty teams">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Team</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Team</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <section className="team-area four pt-100 pb-70">
        <Container>
          <div className="teamme-text">
            <H4>Team Members</H4>
            <H2>Expert Team</H2>
          </div>
          <Row>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={princi} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Mr.Ajai Singh Rathore</H3>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={princi1} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Mr.Ajay Chauhan </H3>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={saket} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Dr Sanket Joshi </H3>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={Dhillon1} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Dr. Ranu S. Dhillon </H3>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={Adrish1} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Dr. Adrish Bhadra </H3>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={pooja} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Pooja Prajapati</H3>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={vinay} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Vinaykant Rao</H3>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={r19} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Raju saini</H3>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={hemant} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Hemant Rao</H3>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="team-item">
                <div className="top">
                  <img src={r56} alt="Team" />
                </div>
                <div className="bottom">
                  <H3>Deepak Sharma</H3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Team;
