import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link,} from "react-router-dom";
import { Navbar } from "react-bootstrap";
import logo1 from "../../assests/image/logo/logo1.png";

const Navbar1 = () => {
  const [click, setClick] = useState(true);
  window.addEventListener(
    "resize",
    function (event) {
      if (window.innerWidth <= 991) {
        setClick(false);
      } else {
        setClick(true);
      }
    },
    true
  );
  useEffect(() => {
    if (window.innerWidth <= 991) {
      setClick(false);
    } else {
      setClick(true);
    }
  }, [window.innerWidth]);

  console.log(click);
  console.log(window.innerWidth);
  useEffect(() => {
    const Script = document.createElement("script");
    // id should be same as given to form element
    const Form = document.getElementById("donateForm");
    Script.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    Script.setAttribute("data-payment_button_id", "pl_Ir2PNyi8F6Z2J4");
    Form.appendChild(Script);
  }, []);
  
  return (
    <>
      <Navbar
        bg="light"
        expand={click}
        style={{ position: "sticky", top: 0, zIndex: 1020 }}
      >
        <Link to="/">
          <img src={logo1} className="logo-header" alt="" />
        </Link>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <ul>
            <li>
              <Link to="#">About us</Link>
              <ul>
                <li>
                  <Link to="/VisionMission">Vision & Mission</Link>
                </li>

                <li>
                  <Link to="/BoardsMember">Boards Member</Link>
                </li>
                <li>
                  <Link to="/Team">Team</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">Focus area of work</Link>
              <ul>
                <li>
                  <Link to="/Education">Education</Link>
                </li>
                <li>
                  <Link to="/Health">Health Care</Link>
                </li>
                <li>
                  <Link to="/Livelihoods">Livelihoods</Link>
                </li>

                <li>
                  <Link to="/Skill_Development">Skill Development</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#">Projects</Link>
              <ul>
                <li>
                  <Link to="/JhulaGhar">Jhula Ghar</Link>
                </li>
                <li>
                  <Link to="/LivelihoodProject">Rural Livelihood Project</Link>
                </li>
                <li>
                  <Link to="/HealthNutrition">
                    Health & Nutrition Promotion
                  </Link>
                  <Link to="/HealthNutrition" className="emp">
                    Programs
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/PressReleases">Press Releases</Link>
            </li>

            <li>
              <Link to="/EventsGallery">Photo Gallery</Link>
            </li>
            <li>
              <Link to="/Report">Annual Reports</Link>
            </li>
            <li>
              <Link to="/Contact">Contact Us</Link>
            </li>
          </ul>
        </Navbar.Collapse>
        <div className="nav-bt">
          <form className="donatefm" id="donateForm">
            {" "}
          </form>
        </div>
      </Navbar>
    </>
  );
};

export default Navbar1;
