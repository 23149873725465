import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { H1, H2, H3, P, H5, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import education2 from "../../assests/image/education2.jpg";
import d2 from "../../assests/image/d2.jpg";
import d51 from "../../assests/image/biogas/d51.jpg";

function ProposedProjects() {

  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Proposed Projects</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Proposed Projects</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <div className="details-item">
                <H2>Proposed Projects</H2>
                <div className="details-jhulaghar">
                <Row>
                  <Col md={9}>
                  
                    <H5>Educational Project Proposal</H5>
                    
                    <P>
                      Hindupat Institute of Teacher Training is a brainchild of
                      a very enthusiastic, dedicated and foresighted person of
                      eminence Hon. Digvijay Singhji, Ex. CM of Madhya Pradesh.
                      The governing board includes eminent educationalists,
                      technocrats and professionals. HITT presents an
                      unparalleled, qualitative, exhaustive, up-to-date,
                      easy-paced training to all candidates who aspire to take
                      up teaching as their profession
                    </P>
                    <Link to="/Proposed_Projects_Detail/1" className="press-view ">View More</Link>
                  
                  </Col>
                  <Col md={3}>
                    <img src={education2} alt=""/>
                  </Col>
                </Row>
                </div>
                <div className="details-jhulaghar">
                <Row className="mt-4 mb-4">
                  <Col md={9}>
                  
                    <H5>Sewing center for women</H5>
                    <P>
                      Hindupat Institute of Teacher Training is a brainchild of
                      a very enthusiastic, dedicated and foresighted person of
                      eminence Hon. Digvijay Singhji, Ex. CM of Madhya Pradesh.
                      The governing board includes eminent educationalists,
                      technocrats and professionals. HITT presents an
                      unparalleled, qualitative, exhaustive, up-to-date,
                      easy-paced training to all candidates who aspire to take
                      up teaching as their profession
                    </P>
                    <Link to="/Proposed_Projects_Detail/2"  className="press-view">View More</Link>
                 
                  </Col>
                  <Col md={3}>
                    <img src={d2} alt=""/>
                  </Col>
                </Row>
                </div>
                <div className="details-jhulaghar">
                <Row className="mt-4 mb-4">
                  <Col md={9}>
                    <H5>Bio Gas Project Proposal</H5>
                    <P>
                      Hindupat Institute of Teacher Training is a brainchild of
                      a very enthusiastic, dedicated and foresighted person of
                      eminence Hon. Digvijay Singhji, Ex. CM of Madhya Pradesh.
                      The governing board includes eminent educationalists,
                      technocrats and professionals. HITT presents an
                      unparalleled, qualitative, exhaustive, up-to-date,
                      easy-paced training to all candidates who aspire to take
                      up teaching as their profession
                    </P>
                    <Link to="/Proposed_Projects_Detail/3"  className="press-view">View More</Link>
                  </Col>
                  <Col md={3}>
                    <img src={d51} alt="" />
                  </Col>
                </Row>
              </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      
    </>
  );
}

export default ProposedProjects;
