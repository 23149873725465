import React from "react";
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Modal from 'react-modal';
import  axios from "axios";
// const customStyles = {
//   content: {
//     top: '50%',
//     padding:'30px 20px',
//     left: '50%',
//     right: 'auto',
//     color:'#fff',
//     bottom: 'auto',
//     marginRight: '-50%',
//     backgroundColor:'#000',
//     transform: 'translate(-50%, -50%)',
//   },
// };
const Form1 = () => {
  // const [modalIsOpen, setIsOpen] = React.useState(false);

  // function openModal() {
  //   setIsOpen(true);
  // }
  // function closeModal() {
  //   setIsOpen(false);
  // }
  return (
    <Formik
      initialValues={{
        Name: "",
        Email: "",
        Subject: "",
        from_phone: "",
        message: "",
      }}
      validate={(values) => {
        const errors = {};

        if (!values.Name) {
          errors.Name = "Required";
        }
        if (!values.Subject) {
          errors.Subject = "Required";
        }
        if (!values.message) {
          errors.message = "Required";
        }
        if (!values.from_phone) {
          errors.from_phone = "Required";
        } else if (!/^[6-9]\d{9}$/i.test(values.from_phone)) {
          errors.from_phone = "Invalid Number";
        }

        if (!values.Email) {
          errors.Email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)
        ) {
          errors.Email = "Invalid email address";
        }
        return errors;
      }}
        onSubmit={(values, {setSubmitting,resetForm }) => {
        console.log(values);
        //Make API calls here
        const bodyFormData = {};
            bodyFormData.name = values.Name;
            bodyFormData.subject = values.Subject;
            bodyFormData.email = values.Email;
            bodyFormData.contact = values.from_phone;
            bodyFormData.message =values.message;
            const Livechaturl = `http://192.168.29.38:3000/api/public/sendmail`;
              axios.post(Livechaturl, bodyFormData)
                .then((response) => {
                  setTimeout(() => {
                    setSubmitting(false);
                    alert(
                      `Submitted Successfully ->  ${JSON.stringify(response.data.data, null, 2)}`
                      
                    );
                    // openModal()
                  }, 2000);
                }).catch(function (error) {
                  console.log(error);
                });
                resetForm({values:''})
      }}
     
      render={({ submitForm, isSubmitting, values }) => (
        <div className="fo-text">
          <h1>Feel Free To Write Us Message.</h1>
          <Form> 
            <Container>
              <Row>
                <Col md={6}>
                  <Field
                    label="Full Name"
                    name="Name"
                    type="text"
                    id="Name"
                    validate="required"
                    component={ReactstrapInput}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    type="email"
                    label="Email Address"
                    name="Email"
                    id="Email"
                    validate="required"
                    component={ReactstrapInput}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    type="text"
                    label="Contact Number"
                    name="from_phone"
                    id="from_phone"
                    validate="required"
                    component={ReactstrapInput}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    type="text"
                    label="Subject"
                    name="Subject"
                    id="Subject"
                    validate="required"
                    component={ReactstrapInput}
                  />
                </Col>
               
                <Col md={12}>
                  <Field
                    type="text"
                    label="Leave a comment here"
                    name="message"
                    id="message"
                    validate="required"
                    component={ReactstrapInput}
                    style={{ height: "100px" }}
                  />
                </Col>
                <Col xs={12}>
                  <Button type="submit"className="btn1-primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
          {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>your query submitted successfully</div>
        <button onClick={closeModal}
        style={{color:'#000',marginTop:'10px',float:'right'}}
        >close</button>
      </Modal> */}
        </div>
        
      )}
    />
  );
};
export default Form1;
