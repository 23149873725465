import React from "react";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import { Container, Row, Col } from "react-bootstrap";
import { IoLogoTwitter, IoLogoYoutube } from "react-icons/io";
import { FiInstagram } from "react-icons/fi";
import { TiSocialFacebook } from "react-icons/ti";
import {
  FaQuoteLeft,
  FaSearch,
  FaUserAlt,
  FaDollarSign,
  FaPhoneAlt,
  FaRegEnvelope,
} from "react-icons/fa";
import { Form, Input, Button, Radio } from "antd";
import { H2, H3, H4, P, Span } from "../../components/Typography/index";
import "./Donations.css";
import { Link } from "react-router-dom";
import blogdetails1 from "../../assests/image/donate/blogdetails1.jpg";
import blogdetails2 from "../../assests/image/donate/blogdetails2.jpg";
import blogdetails3 from "../../assests/image/donate/blogdetails3.jpg";
import blogdetails4 from "../../assests/image/donate/blogdetails4.jpg";
function DonationDetails() {
  return (
    <>
      <Navbar />
      <div className="page-title-area title-bg-ty1">
        <Container>
          <div className="title-item">
            <H3>Donation Details</H3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Donation Details</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <div className="donation-details-area ptb-100">
        <Container>
          <Row>
            <Col lg={8}>
              <div className="details-item">
              
                  <H3>
                    Treatment help donation for Alia Jesni who is in serious
                    case of cancer
                  </H3>
                  <P>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labor et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in sed quia non
                    numquam eius modi tempora incidunt ut labore et dolore
                    magnam aliquam quaerat voluptatem.
                  </P>
                  <blockquote>
                    <i>
                      <FaQuoteLeft />
                    </i>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do this eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Ut enim ad minim veiam, quis nostrud
                    exercitation ullamco laboris nisi ut aliquip ex ea codo
                    consequat. Duis aute irure dolor.
                  </blockquote>
                  <P>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look
                  </P>
                  <P>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labor et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                   
                  </P>
                </div>
            
              <div className="details-share"></div>
              <div className="details-payment">
                <H3>Select payment method</H3>
                <Form>
                  <Radio.Group className="mb-3">
                    <Radio value={1}>Paypal</Radio>
                    <Radio value={2}>Debit Card</Radio>
                    <Radio value={3}>Credit Card</Radio>
                    <Radio value={4}>Other</Radio>
                  </Radio.Group>

                  <Form.Item label="">
                    <Input
                      prefix={<FaUserAlt className="site-form-item-icon" />}
                      type="text"
                      placeholder="First name"
                    />
                  </Form.Item>

                  <Form.Item label="">
                    <Input
                      prefix={<FaUserAlt className="site-form-item-icon" />}
                      type="text"
                      placeholder="Last name"
                    />
                  </Form.Item>
                  <Form.Item label="">
                    <Input
                      prefix={<FaRegEnvelope className="site-form-item-icon" />}
                      type="text"
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item label="">
                    <Input
                      prefix={<FaPhoneAlt className="site-form-item-icon" />}
                      type="text"
                      placeholder="Phone number"
                    />
                  </Form.Item>
                  <Form.Item label="">
                    <Input
                      prefix={<FaDollarSign className="site-form-item-icon" />}
                      type="text"
                      placeholder="$100.00"
                    />
                  </Form.Item>
                  <div class="text-center">
                    <Button type="submit" class="btn common-btn">
                      Donate Now
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            {/* RRRRRRRRRR */}
            <Col lg={4}>
              <div className="widget-area">
                <div className="search widget-item">
                  <Form>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                    <Button type="submit" className="btn">
                      <i>
                        <FaSearch />
                      </i>
                    </Button>
                  </Form>
                </div>
              </div>
              <div className="post widget-item">
                <H3>Popular Post</H3>
                <div className="post-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src={blogdetails1} alt="Details" />
                    </li>
                    <li>
                      <H4>
                        <Link to="/">
                          Donate for nutrition less poor people
                        </Link>
                      </H4>
                    </li>
                  </ul>
                </div>
                <div className="post-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src={blogdetails2} alt="Details" />
                    </li>
                    <li>
                      <H4>
                        <Link to="/">Charity meetup in Berlin next year</Link>
                      </H4>
                    </li>
                  </ul>
                </div>
                <div className="post-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src={blogdetails3} alt="Details" />
                    </li>
                    <li>
                      <H4>
                        <Link to="/">
                          Donate for poor people for food & water
                        </Link>
                      </H4>
                    </li>
                  </ul>
                </div>
                <div className="post-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src={blogdetails4} alt="Details" />
                    </li>
                    <li>
                      <H4>
                        <Link to="/">
                          Little Sanjana joined in a charity to help people
                        </Link>
                      </H4>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="common-right-content widget-item">
                <H3 className="t-dt">Archives</H3>
                <ul>
                  <li>
                    <Link to="/">January 2020</Link>
                  </li>
                  <li>
                    <Link to="/">May 2020</Link>
                  </li>
                  <li>
                    <Link to="/">April 2020</Link>
                  </li>
                  <li>
                    <Link to="/">June 2020</Link>
                  </li>
                </ul>
              </div>
              <div className="common-right-content widget-item">
                <H3 className="t-dt">Categories</H3>
                <ul>
                  <li>
                    <Link to="/">Education (10)</Link>
                  </li>
                  <li>
                    <Link to="/">Medical (25)</Link>
                  </li>
                  <li>
                    <Link to="/">Food & Water (14)</Link>
                  </li>
                  <li>
                    <Link to="/">National Charity (2)</Link>
                  </li>
                  <li>
                    <Link to="/">Cloth (4)</Link>
                  </li>
                </ul>
              </div>
             
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DonationDetails;
