import React from "react";
import "./Project.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaSearch,
} from "react-icons/fa";
import { Form, Input, Button,} from "antd";
import { H2, H3, H4, H5, H6, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
function HealthNutrition() {
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="project-text">
              <div className="project-text2">
                <H6>Health & Nutrition Promotion Programs</H6>
              </div>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Span>Health & Nutrition Promotion Programs</Span>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className="donation-details-area mb-4">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="details-item">
              <H2>HEALTH & NUTRITION PROMOTION PROGRAMS</H2>
              <div className="details-jhulaghar">
                  <H5>Background</H5>
                  <P>
                    It is important to address the inequities in delivery of
                    health services in marginalized areas. National Rural Health
                    Mission, which is one of the most ambitious programme of
                    Government of India since after independence, too is not
                    able to address health and nutrition in marginalized areas.
                    In underserved areas, especially rural and tribal, the
                    education level is low, the economy is weak and
                    infrastructure facilities are poorly developed and health
                    services are less or remotely available and sometimes
                    inaccessible. In such a condition the major burden of
                    disease is borne by the poor community living there,
                    especially women and children. The children and adolescents
                    in marginalized areas are malnourished because of poor
                    socio-economic conditions.
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>The Programme</H5>
                  <P>
                    The project is about promotion of community health through
                    improved nutrition and health services. The target
                    population of the project is eligible women (15-49 years
                    age), children and adolescent girls and boys (10-16 years).
                    Deterioration in nutritional status of children is an issue
                    which needs to be address with all focus. The adolescent
                    girls are the future mothers, so their health and nutrition
                    are important. The information about diet and nutritional
                    status of adolescents is very little, particularly from
                    rural areas. The project has been strategically planned to
                    address health and nutrition issues with a systematic
                    approach. The program interventions like awareness
                    generation, referrals and supplementation of nutrition and
                    medicines will be as a part of implementation activities.
                  </P>
                  </div>
                  <div className="details-jhulaghar">
                  <H5>Objective</H5>
                  <ul>
                    <li>
                      Improve access to utilization of primary and preventive
                      health care services by poor & marginalized population,
                      especially women and children.
                    </li>
                    <li> Enhance the quality of health services.</li>
                    <li>
                      Strengthen monitoring of health services by the community
                      and local level institutions.
                    </li>
                    <li>
                      Through health outreach enhancing school enrollment to
                      100% at least in 125 villagesof the region
                    </li>
                  </ul>
                  
                  <div className="details-share">
                    {/* <Row>
                      <Col sm={6} lg={6}>
                        <div className="left">
                          <ul>
                            <li>
                              <Span>Share:</Span>
                            </li>
                            <li>
                              <Link to="/" target="_blank">
                                <i>
                                  <TiSocialFacebook />
                                </i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/" target="_blank">
                                <i>
                                  <IoLogoTwitter />
                                </i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/" target="_blank">
                                <i>
                                  <IoLogoYoutube />
                                </i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/" target="_blank">
                                <i>
                                  <FiInstagram />
                                </i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col sm={6} lg={6}>
                        <div class="right">
                          <ul>
                            <li>
                              <Span>Tags:</Span>
                            </li>
                            <li>
                              <Link to="/">#Donation</Link>
                            </li>
                            <li>
                              <Link to="/">#Food</Link>
                            </li>
                            <li>
                              <Link to="/">#Help</Link>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row> */}
                  </div>
                </div>
              </div>
            </Col>
            {/* RRRRRRRRRR */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HealthNutrition;
