import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import "./work.css";
import Carousel, { Modal, ModalGateway } from "react-images";
import group1 from "../../assests/image/group1.png";
import group2 from "../../assests/image/group2.png";
import group3 from "../../assests/image/group3.png";
import group4 from "../../assests/image/group4.png";
import group5 from "../../assests/image/group5.png";
import group6 from "../../assests/image/group6.png";
const photos = [
  {
    src: group1,
    width: 4,
    height: 3,
  },
  {
    src: group2,
    width: 4,
    height: 3,
  },
  {
    src: group3,
    width: 4,
    height: 3,
  },
  {
    src: group4,
    width: 4,
    height: 3,
  },
  {
    src: group5,
    width: 4,
    height: 3,
  },
  {
    src: group6,
    width: 4,
    height: 3,
  },
];

const Lightboxcode3 = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <div className="light1box mb-1">
        <Gallery photos={photos} onClick={openLightbox} />
      </div>

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};
export default Lightboxcode3;
