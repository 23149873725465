import React, { useState, useEffect } from "react";
import "./work.css";
import Lightboxcode1 from "../Work/Lightboxcode1";
import { Container, Row, NavLink, Col, Tab, Tabs } from "react-bootstrap";
import { H6, H5,H2, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import campus2 from "../../assests/image/banner/campus2.png";
import ed1 from "../../assests/image/slider/ed1.jpg";
import ed2 from "../../assests/image/slider/ed2.jpg";
import ed3 from "../../assests/image/slider/ed3.jpg";
import ed4 from "../../assests/image/slider/ed4.jpg";
import ed5 from "../../assests/image/slider/ed5.jpg";
import ed6 from "../../assests/image/slider/ed6.jpg";
import RESULT12 from "../../assests/image/award/RESULT12.png";
import RESULT from "../../assests/image/award/RESULT.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { Image } from "antd";
function Education() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Topheader />
      <Navbar />

      <section className="eduction-1">
        <Container>
          <Row>
            <Col md={12}>
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
              >
                <div className="slider-text">
                  <img src={ed1} alt="lldd" />
                </div>
                <div className="slider-text">
                  <img src={ed2} alt="lldd" />
                </div>
                <div className="slider-text">
                  <img src={ed3} alt="lldd" />
                </div>
                <div className="slider-text">
                  <img src={ed4} alt="lldd" />
                </div>
                <div className="slider-text">
                  <img src={ed5} alt="lldd" />
                </div>
                <div className="slider-text">
                  <img src={ed6} alt="lldd" />
                </div>
              </Carousel>
            </Col>
          </Row>
          <Row className="mt-5 mb-4">
            <Col md={6}>
              <div className="ght">
                <img src={campus2} />
              </div>
            </Col>
            <Col md={6}>
              <div className="education-2">
                <H2>Education</H2>
                <P>
                  Our Goal is to provide quality education at low cost to
                  families living in rural areas
                </P>
                <P>
                  In the education sector we run 3 CBSE schools in Guna
                  District. Started in 2010, our schools have over 2,000
                  admitted students from low-income families from playschool
                  till grade 10. In 2014 we started a teachers training college
                  The college has a 2 year
                  {/* under the name of Hindupat Institute of Teachers Training
                  (HITT). The college has a 2 year */}
                </P>
                <P>
                  masters program and a 4 year under-graduate program. Our
                  knowledge partners include Columbia University  School of
                  International and Public Affairs (SIPA) and Teachers College
                  and Avanti Fellows.
                </P>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col md={12}>
              <div className="structure-text">
                <Row>
                  <Col sm={4}>
                    <div className="award-text school-area gr">
                      <P>
                        Hindupat Public School <br /> Raghogarh{" "}
                      </P>
                      <H6>(Nursery - XII CBSE)</H6>
                      <NavLink
                        className="trnav-text33"
                        onClick={() => {
                          window.open(
                            "https://www.hpsraghogarh.com/",
                            "_blank"
                          );
                        }}
                      >
                        Click here to Visit Website
                      </NavLink>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="award-text school-area yl">
                      <P>
                        Hindupat Public School <br /> Aron{" "}
                      </P>
                      <H6>(Nursery - X CBSE)</H6>
                      <NavLink
                        className="trnav-text33"
                        onClick={() => {
                          window.open("https://www.hpsaron.com/", "_blank");
                        }}
                      >
                        Click here to Visit Website
                      </NavLink>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="award-text school-area or">
                      <P>
                        {" "}
                        Hindupat Public School <br /> Jamner{" "}
                      </P>
                      <H6>(Nursery - X CBSE)</H6>
                      <NavLink
                        className="trnav-text33"
                        onClick={() => {
                          window.open("https://www.hpsjamner.com/", "_blank");
                        }}
                      >
                        Click here to Visit Website
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <div className="structure-text">
                <h2 class="main-head">Our Achievements</h2>
                <div className="jt-text">
                  <H5>Hindupat Public School Raghogarh</H5>
                  <span className="cert-text">
                    Toppers of 2020-2021 in Class 10th & 12th{" "}
                  </span>
                </div>

                <Row>
                  <Col sm={6}>
                    <div className="award-text">
                      <Image src={RESULT12} preview={{ src: RESULT12 }} />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="award-text">
                      <Image src={RESULT} preview={{ src: RESULT }} />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="structure-text">
                <h2 class="main-head">Curriculum Area</h2>
                <div className="jt-text">
                  <H5>ANNUAL FUNCTION-2019-2020</H5>
                </div>
                {/* <div className="award-text achive"> */}
                <Lightboxcode1 />
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Education;
