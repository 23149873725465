import React from "react";
import "./About.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { H2, H5, P, Span } from "../../components/Typography/index";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import vission from "../../assests/image/vission.png";
import mission from "../../assests/image/mission.png";

function VisionMission() {
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="page-title-area title-bg-one">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>VisionMission</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>VisionMission</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <div className="donation-details-area">
        <Container>
          <div className="details-jhulaghar">
            <Row>
              <Col md={12}>
                <Row>
                  <Col lg={7}>
                  <div className="details-jhulaghar-2">
                    <H2>Our Vision</H2>
                    <P>
                      Pragati Manav Seva Sansthan invasions of creating a world
                      where diversity is respected and a society based on
                      democratic values where all citizen exercising their
                      rights, achieve equity, social justice and become
                      self-reliant.
                    </P>
                    <P>Pragati's focus and entry point is the child and holistic growth of a child is a starting point of institutional intervention. The unit of development is the family. Organizing community is the approach, through which empowerment, capacity building and social transformation are attempted. Pragati concentrates on working with youth for channelizing their positive
                       energies for societal good</P>
                      <P>Work closely with institutions of democratic governance for livelihoods with dignity, fostering women's empowerment and social justice 
                        for all marginalized section of society.</P>
                        </div>
                  </Col>
                  <Col lg={5}>
                    <div className="ty">
                      <img src={vission} alt="" />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <Col md={12}>
              <div className="details-jhulaghar">
                <Row>
                   <Col lg={5}>
                   <div className="ty1">
                      <img src={mission} alt="" />
                    </div>
                  </Col> 
                  <Col lg={7}>
                  <div className="details-jhulaghar-1">
                    <H2>Our Mission</H2>
                 {/* <P>
                    The mission of the livelihood programmes is to facilitate processes that 
                    enhance productivity byimproved technology, innovations, 
                    and skills of the local human resource. The organization believes that optimal utilization of local resources (natural and human resource) and access to capital can foster inclusive growth. Organizationwould facilitate creation of such institutions whererural youth become employable, skilled andengaged in gainful productive activities. The organization would implement programmes that promote rural entrepreneurship which would have 
                    positive impact on economic development of the region.
                    </P>  */}
                    <P> Our mission is to promote and provide qualitative education at affordable costs to children and communities, 
                      which are socially and economically deprived.</P>
                    <P>To "Ensure Education For ALL" and cut the drop-out rate from school and introduce Life Skill Education in    schools' programme.
                    </P>
                    <P>To promote awareness on health, especially women and child health and creation of community systems    for healthy society.
                    </P>
                    <P>To act as resource agency that encourages technology and innovation to enhance productivity and  
                        create employment for rural youth.</P>
                        <P>To promote policies and programmes that ensures inclusive development through education, skill   
                           development and promoting entrepreneurship among men and women.</P>
                           </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default VisionMission;
